import React, { useState, useEffect } from "react";

import StudCompleteIcon from "../assets/images/icons/complete__stud.svg";
import SettingIcon from "../assets/images/icons/setting__icon.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  handleActiveTab,
  handleSwapTabs,
  handleRemoveDiamondViewEdit,
  handleRemoveSettingViewEdit,
} from "../actions/header/headerActions";
import ChooseYourSetting from "../component/ApplicationPreview/ChooseYourSetting";
import SelectYourDiamond from "../component/ApplicationPreview/SelectYourDiamond";
import CompleteYourRing from "../component/ApplicationPreview/CompleteYourRing";
import {
  handleHideDetailsPage,
  handleHideCompleteEarningTwoDiamond,
} from "../actions/chooseYourSetting/chooseYourSettingActions";
import { handleHideDetailsPage as handleHideDiamondDetailsPage } from "../actions/selectYourDiamond/selectYourDiamond";
import { handleHideEditChooseThisMounting } from "../actions/chooseYourEarning/chooseYourEarningActions";
import styled from "styled-components";
import SelectYourDiamondService from "../services/select-your-diamond.service";
import { useLocation, useNavigate } from "react-router-dom";

export const Header = () => {
  const dispatch = useDispatch();
  const currentTab = useSelector((state) => state.headerReducer);

  const chooseYourSetting = useSelector(
    (state) => state.chooseYourSettingReducer
  );
  const search = useLocation().search;
  const tabName = new URLSearchParams(search).get("tabName");
  const CurrentTab = new URLSearchParams(search).get("tabName");
  const paramdealerId = new URLSearchParams(search).get("DealerID");
  const paramdealerId2 = new URLSearchParams(search).get("DealerLink");
  const [activeTab, setActiveTab] = useState("");
  const [tabDetails, setTabDetails] = useState([]);
  const [tabSetting, setTabSetting] = useState(false);
  const [tabdiamond, setTabDiamond] = useState(false);
  const [tabdiamondEarning, setTabDiamondEarning] = useState(false);
  const [choosetabdiamondEarning, setChooseTabDiamondEarning] = useState(false);
  const [hidechoosetabdiamondEarning, setHideChooseTabDiamondEarning] =
    useState(false);
  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [toggle, setToggle] = useState(true);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });

  const chooseYourEarning = useSelector(
    (state) => state.handleChooseYourEarningStudSetting
  );

  const [isViewEditShow, setIsViewEditShow] = useState(false);

  const loginDetails = useSelector((state) => state.loginDetailsReducer);

  useEffect(() => {
    if (paramdealerId || paramdealerId2) {
      handleGetstudBuilderOptiondetails(paramdealerId?paramdealerId:paramdealerId2);
    }
  }, []);

  const initialOptionsData = {
    studbuilderLinkURL: "",
    internalUseLink: true,
    showOnlyDiamondSearch: false,
    showDealerDiamondsSKU: true,
    showScheduleViewing: true,
    showAddress: true,
    showCallForPrice: false,
    applyGstTax: true,
    gstTaxValue: "",
    showPinterestShare: true,
    showTwitterShare: true,
    showFacebookShare: true,
    showFacebookLike: true,
    showAddToCartButton: true,
    ShoppingCartURL: "",
    showViewCartButton: true,
    ShowInstagramPlus: true,
  };
  const [myOptionsData, setMyOptionsData] = useState(initialOptionsData);

  const handleGetstudBuilderOptiondetails = (id) => {
    let inputData = {
      dealerID: id,
    };

    try {
      SelectYourDiamondService.GetstudBuilderOptiondetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          if (message == "Success") {
            if (responseData) {
              if (response.data.responseData.length > 0) {
                setMyOptionsData((prevState) => ({
                  ...prevState,
                  studbuilderLinkURL: responseData.studBuilderURL,
                  internalUseLink: responseData.internalLinkSB,
                  showOnlyDiamondSearch: responseData.onlyDiamondSearch,
                  showDealerDiamondsSKU: responseData.stud_ShowSKU,
                  showScheduleViewing: responseData.showScheduleView,
                  showAddress: responseData.showAddresses,
                  showCallForPrice: responseData.callForPricestud,
                  applyGstTax: responseData.showGstPrice,
                  gstTaxValue: responseData.taxRate,
                  showPinterestShare: responseData.showPinterestShare,
                  showTwitterShare: responseData.showTwitterShare,
                  showFacebookShare: responseData.showFacebookShare,
                  showFacebookLike: responseData.showFacebookLike,
                  showAddToCartButton: responseData.showAddToCartButtonStud,
                  ShoppingCartURL: responseData.diamondstudshoppingurl,
                  showViewCartButton: responseData.showViewCartButtonStud,
                  ShowInstagramPlus: responseData.showInstagramShare,
                }));
              }
            }
          } else {
            console.log("Not Binded");
          }
        })
        .catch((error) => {
          console.log(error);
          setMyOptionsData((prevState) => ({
            showOnlyDiamondSearch: false,
          }));
        });
    } catch (error) {
      console.log(error);
      setMyOptionsData((prevState) => ({
        showOnlyDiamondSearch: false,
      }));
    }
  };

  useEffect(() => {
    if (chooseYourEarning) {
      if (chooseYourEarning.isViewEarningPageDetails) {
        setTabDiamondEarning(true);
      } else {
        setTabDiamondEarning(false);
      }
      if (chooseYourEarning.isViewChooseMountingPage) {
        setChooseTabDiamondEarning(true);
      } else {
        setChooseTabDiamondEarning(false);
      }
      if (chooseYourEarning.isViewEditChooseYourSetting) {
        setHideChooseTabDiamondEarning(true);
      } else {
        setHideChooseTabDiamondEarning(false);
      }
    }
  }, [chooseYourEarning]);

  const handleTabClick = (tab, index) => {
    if (choosetabdiamondEarning) {
      let tabDetailsArray = tabDetails;
      if (index == tabDetailsArray.length - 1) {
        let temp = tabDetailsArray[0];
        tabDetailsArray[0] = tabDetailsArray[1];
        tabDetailsArray[1] = temp;
        dispatch(handleSwapTabs(tabDetailsArray));
        dispatch(handleActiveTab(tab));
      }

      if (tab == "completeYourRing") {
        if (currentTab) {
          if (currentTab.isSettingViewEdit && currentTab.isDiamondViewEdit) {
            dispatch(handleActiveTab(tab));
          }
        }
      }
      dispatch(handleActiveTab(tab));
    }
  };

  useEffect(() => {
    if (previewcolor) {
      if (previewcolor.buttonColor) {
        setVarColorObj({
          ...varColorObj,
          callToActionColor: previewcolor.buttonColor.callToActionColor,
          hoverColor: previewcolor.buttonColor.hoverColor,
          columnHeaderAccentColor:
            previewcolor.buttonColor.columnHeaderAccentColor,
          linkColor: previewcolor.buttonColor.linkColor,
        });
        setToggle(previewcolor.toogle);
      }
    }
  }, [previewcolor.toogle, toggle]);

  useEffect(() => {
    if (currentTab) {
      if (CurrentTab) {
        setActiveTab(CurrentTab);
      } else {
        setActiveTab(currentTab.activeTab);
      }
      if (currentTab.tabDetails) {
        setTabDetails(currentTab.tabDetails);

        if (currentTab.isSettingViewEdit) {
          setTabSetting(true);
        } else {
          setTabSetting(false);
        }
        // if (currentTab.isDiamondViewEdit) {
        //   setTabDiamond(true);
        // } else {
        //   setTabDiamond(false);
        // }
      }
    }
  }, [currentTab, currentTab.isSettingViewEdit]);

  useEffect(() => {
    if (chooseYourSetting) {
      if (chooseYourSetting.isViewCompleteEarningTwoDiamondDetails) {
        setTabDiamond(true);
      } else {
        setTabDiamond(false);
      }
    }
  }, [chooseYourSetting]);

  // Edit view Select Your diamond
  useEffect(() => {
    if (chooseYourSetting.isViewEditShow == true) {
      setIsViewEditShow(true);
    } else {
      setIsViewEditShow(false);
    }
  }, [chooseYourSetting.isViewEditShow]);

  const handleViewSetting = () => {
    dispatch(handleActiveTab("chooseYourSetting"));
  };

  const handleEditSetting = () => {
    dispatch(handleActiveTab("chooseYourSetting"));
    dispatch(handleHideDetailsPage());
    dispatch(handleRemoveSettingViewEdit());
  };

  const [tableClassAllow, setTableClassAllow] = useState(false);
  const isSocialPage = new URLSearchParams(search).get("isSocialPage");
  const diamondId = new URLSearchParams(search).get("diamondId");
  const paramDetailLinkURL = new URLSearchParams(search).get(
    "paramDetailLinkURL"
  );
  const socialpageNo = new URLSearchParams(search).get("pageNo");
  const isAllowed = new URLSearchParams(search).get("isAllowed");
  const socialpageName = new URLSearchParams(search).get("pageName");
  const paramCut = new URLSearchParams(search).get("paramCut");
  const paramSize = new URLSearchParams(search).get("size");
  const paramPageSize = new URLSearchParams(search).get("pagesize");
  const paramPageNo = new URLSearchParams(search).get("pageNo");
  const paramTotalPrice = new URLSearchParams(search).get("TotalPrice");
  const paramdealerId1 = new URLSearchParams(search).get("dealerID");
  const pairId = new URLSearchParams(search).get("pairId");
  const totalPrice = new URLSearchParams(search).get("totalPrice");
  const MountEdit = new URLSearchParams(search).get("mountEdit");
  const DiamondViewEdit = new URLSearchParams(search).get("diamondViewEdit");
  const navigate = useNavigate();

  const handleViewDiamond = () => {
    // dispatch(handleActiveTab("selectYourDiamond"));

    navigate(
      "/viewDiamondDetails" +
        `?isSocialPage=True&DealerID=${paramdealerId}&paramDetailLinkURL=${paramDetailLinkURL}&pageNo=1&isAllowed=True&paramCut=${paramCut}&diamondId=${diamondId}&isTab=false&tabName=selectYourDiamond`
    );
  };

  const handleEditDiamond = () => {
    // dispatch(handleActiveTab("selectYourDiamond"));

    // dispatch(handleHideCompleteEarningTwoDiamond());

    navigate(
      "/selectYourDiamond" +
        `?DealerLink=${paramdealerId}&tabName=selectYourDiamond&isTab=True&isChange=True`
    );

    // dispatch(handleHideDiamondDetailsPage());
    // dispatch(handleRemoveDiamondViewEdit());
  };

  const handleEditTabChooseYourMounting = () => {
    // dispatch(handleActiveTab("chooseYourSetting"));

    // dispatch(handleHideEditChooseThisMounting());
    navigate(
      "/chooseYourSetting" +
        `?isSocialPage=True&DealerID=${paramdealerId}&dealerID=${paramdealerId1}&pageNo=1&isAllowed=True&paramCut=${paramCut}&size=${paramSize}&pairId=${pairId}&totalPrice=${totalPrice}&isTab=false&tabName=chooseyourmounting&diamondViewEdit=True`
    );
  };

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: #3b4452 !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: #fff;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: #fff;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: #fff;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: #fff;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: #fff !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: #fff !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: #fff !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: #fff !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }

    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
  `;

  // For Slider
  // & .ant-slider-track {
  //   border-color: ${varColorObj.linkColor};
  //   background: ${varColorObj.linkColor};
  // }

  // & .ant-slider-handle {
  //   border-color: ${varColorObj.linkColor};
  //   background: ${varColorObj.linkColor};
  // }

  return (
    <>
      <div class="bcontainer">
        <div class="breadcrumb">
          {/* {myOptionsData.showOnlyDiamondSearch == false && ( */}
          <>
            {tabDetails &&
              tabDetails.map((tab, index) => {
                return (
                  <li
                    // className={activeTab == tab.tabValue && "active"}
                    className={tabName == "selectYourDiamond" && "active"}
                    onClick={() => handleTabClick("selectYourDiamond")}
                  >
                    <div className="left__block">
                      <span className="circle">1</span>
                      <div class="breadcrumb__content">
                        <p class="mb-0 text-left">
                          Select Your <br />{" "}
                          <b className="subheading">Diamond</b>
                        </p>
                      </div>
                    </div>

                    <div className="right__block">
                      {DiamondViewEdit == "True" ? (
                        <>
                          {" "}
                          <div className="mr-1">
                            <span className="mr-2" onClick={handleViewDiamond}>
                              View
                            </span>
                            <span>|</span>
                            <span
                              className="ml-2 mr-1"
                              onClick={handleEditDiamond}
                            >
                              Edit
                            </span>
                          </div>
                        </>
                      ) : null}

                      <img src={tab.imgSrc} />
                    </div>
                  </li>
                );
              })}

            {/* {myOptionsData.showOnlyDiamondSearch == false && (
            <> */}
            {!myOptionsData.showOnlyDiamondSearch? (choosetabdiamondEarning ? (
              <li
                // className={activeTab == "chooseYourSetting" && "active"}
                className={tabName == "chooseyourmounting" && "active"}
                // onClick={() => handleTabClick("chooseYourSetting")}
              >
                <div className="left__block">
                  <span className="circle">2</span>
                  <div class="breadcrumb__content">
                    <p class="mb-0 text-left">
                      Choose Your <br /> <b className="subheading">Mounting</b>
                    </p>
                  </div>
                </div>

                <div className="right__block">
                  {MountEdit == "True" ? (
                    <>
                      {" "}
                      <div className="mr-2">
                        <span onClick={handleEditTabChooseYourMounting}>
                          Edit
                        </span>
                      </div>
                    </>
                  ) : null}

                  <img src={SettingIcon} />
                </div>
              </li>
            ) : (
              <li className={tabName == "chooseyourmounting" && "active"}>
                <div className="left__block">
                  <span className="circle">2</span>
                  <div class="breadcrumb__content">
                    <p class="mb-0 text-left">
                      Choose Your <br /> <b className="subheading">Mounting</b>
                    </p>
                  </div>
                </div>
                <div className="right__block">
                  <img src={SettingIcon} />
                </div>
              </li>
            )):null}
            {!myOptionsData.showOnlyDiamondSearch?(tabdiamondEarning ? (
              <li
                // className={activeTab == "completeYourRing" && "active"}
                className={tabName == "completeyourring" && "active"}
                // onClick={() => handleTabClick("completeYourRing")}
              >
                <div className="left__block">
                  <span className="circle">3</span>
                  <div class="breadcrumb__content">
                    <p class="mb-0">
                      Complete Your <br />{" "}
                      <b className="subheading">Earrings</b>
                    </p>
                  </div>
                </div>
                <div className="right__block">
                  <img src={StudCompleteIcon} />
                </div>
              </li>
            ) : (
              <li className={tabName == "completeyourring" && "active"}>
                {/* <p className="mb-0">
                    <span className="circle">3</span> Complete Your Earrings
                  </p> */}
                <div className="left__block">
                  <span className="circle">3</span>
                  <div class="breadcrumb__content">
                    <p class="mb-0 text-left">
                      Complete Your <br />{" "}
                      <b className="subheading">Earrings</b>
                    </p>
                  </div>
                </div>
                <div className="right__block">
                  <img src={StudCompleteIcon} />
                </div>
              </li>
            )):null}
          </>
          {/* )} */}
        </div>
      </div>
      {/* {activeTab == "chooseYourSetting" && <ChooseYourSetting />} */}
      {activeTab == "selectYourDiamond" && <SelectYourDiamond />}
      {/* {activeTab == "completeYourRing" && <CompleteYourRing />} */}
    </>
  );
};

export default Header;
