import {
  VIEW_DIAMOND_DETAILS_PAGE,
  VIEW_EARNING_DETAILS_PAGE,
  VIEW_AddToStud_DETAILS_PAGE,
  HIDE_DIAMOND_DETAILS_PAGE,
  SET_COMPARE_TAB_IDS,
  SET_RESETMINED_TAB,
  SET_RESETLAB_TAB,
  SET_RESETFANCY_TAB,
  SET_RESETREQUEST_TAB,
  SET_DIAMOND_DETAILS,
  SET_SAVESEARCH_MINEDTAB,
  SET_SAVEFILTER_MINEDTAB,
  SET_SAVEEVERYTIME_MINEDTAB,
  SET_SWITCHFILTER_MINEDTAB,
  SET_SAVESEARCH_LABTAB,
  SET_SAVEFILTER_LABTAB,
  SET_SAVEEVERYTIME_LABTAB,
  SET_SWITCHFILTER_LABTAB,
  SET_SAVESEARCH_FANCYTAB,
  SET_SAVEFILTER_FANCYTAB,
  SET_SAVEEVERYTIME_FANCYTAB,
  SET_SWITCHFILTER_FANCYTAB,
  SET_SAVESEARCH_REQUESTTAB,
  SET_SWITCHFILTER_REQUESTTAB,
  SET_SAVEFILTER_REQUESTTAB,
  SET_SAVEEVERYTIME_REQUESTTAB,
} from "../../actions/selectYourDiamond/types";

const initialState = {
  isViewDetails: false,
  selectedRow: null,
  compareData: null,
  resetMined: true,
  resetLab: true,
  resetFancy: true,
  resetRequest: true,

  saveSearchMined: false,
  minedDiamondFilter: {
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    Certificate: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    disabled: {}
  },
  everyTimeFilterChangeMined: {},
  saveSearchLab: false,
  LabDiamondFilter: {
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    Certificate: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    disabled: {}
  },
  everyTimeFilterChangeLab: {},

  saveSearchFancy: false,
  FancyDiamondFilter: {
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    Intensity: [],
    Certificates: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    disabled: {}
  },
  everyTimeFilterChangeFancy: {},
  compareIds: [],
  diamondDetails: {},
  everyTimeFilterChangeRequest: {},
  saveSearchRequest: false,
  RequestFilter: {
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    Intensity: [],
    Certificates: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    disabled: {}
  },
};

export default function handleSelectYourDiamondReducer(
  selectYourDiamond = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case VIEW_DIAMOND_DETAILS_PAGE:
      return {
        ...selectYourDiamond,
        isViewDetails: true,
        selectedRow: payload
      };
    case VIEW_EARNING_DETAILS_PAGE:
      return {
        ...selectYourDiamond,
        isEarningDetails: true,
        selectedRow: payload
      };
    case VIEW_AddToStud_DETAILS_PAGE:
      return {
        ...selectYourDiamond,
        isAddToStudDetails: true,
        selectedRow: payload
      };
    case HIDE_DIAMOND_DETAILS_PAGE:
      return {
        ...selectYourDiamond,
        isViewDetails: false,
        selectedRow: null
      };

    case SET_COMPARE_TAB_IDS:
      return {
        ...selectYourDiamond,
        compareIds: payload
      };
    case SET_RESETMINED_TAB:
      return {
        ...selectYourDiamond,
        resetMined: !selectYourDiamond.resetMined
      };
    case SET_RESETLAB_TAB:
      return {
        ...selectYourDiamond,
        resetLab: !selectYourDiamond.resetLab
      };
    case SET_RESETFANCY_TAB:
      return {
        ...selectYourDiamond,
        resetFancy: !selectYourDiamond.resetFancy
      };
    case SET_RESETREQUEST_TAB:
      return {
        ...selectYourDiamond,
        resetRequest: !selectYourDiamond.resetRequest
      };
    case SET_COMPARE_TAB_IDS:
      return {
        ...selectYourDiamond,
        compareIds: payload
      };
    case SET_DIAMOND_DETAILS:
      return {
        ...selectYourDiamond,
        diamondDetails: payload
      };
    case SET_SAVESEARCH_MINEDTAB:
      return {
        ...selectYourDiamond,
        saveSearchMined: payload
      };
    case SET_SAVEFILTER_MINEDTAB:
      return {
        ...selectYourDiamond,
        everyTimeFilterChangeMined: payload
      };

    case SET_SAVEEVERYTIME_MINEDTAB:
      return {
        ...selectYourDiamond,
        everyTimeFilterChangeMined: payload
      };
    case SET_SWITCHFILTER_MINEDTAB:
      console.log('mined',selectYourDiamond.everyTimeFilterChangeMined);
      return {
        ...selectYourDiamond,
        minedDiamondFilter: selectYourDiamond.everyTimeFilterChangeMined
      };

    case SET_SAVESEARCH_LABTAB:
      return {
        ...selectYourDiamond,
        saveSearchLab: payload
      };
    case SET_SAVEFILTER_LABTAB:
      return {
        ...selectYourDiamond,
        everyTimeFilterChangeLab: payload
      };

    case SET_SAVEEVERYTIME_LABTAB:
      return {
        ...selectYourDiamond,
        everyTimeFilterChangeLab: payload
      };
    case SET_SWITCHFILTER_LABTAB:
      return {
        ...selectYourDiamond,
        LabDiamondFilter: selectYourDiamond.everyTimeFilterChangeLab
      };

    case SET_SAVESEARCH_FANCYTAB:
      return {
        ...selectYourDiamond,
        saveSearchFancy: payload
      };
      case SET_SAVESEARCH_REQUESTTAB:
      return {
        ...selectYourDiamond,
        saveSearchRequest: payload
      };
    case SET_SAVEFILTER_FANCYTAB:
      return {
        ...selectYourDiamond,
        everyTimeFilterChangeFancy: payload
      };

    case SET_SAVEEVERYTIME_FANCYTAB:
      return {
        ...selectYourDiamond,
        everyTimeFilterChangeFancy: payload
      };
    case SET_SWITCHFILTER_FANCYTAB:
      return {
        ...selectYourDiamond,
        FancyDiamondFilter: selectYourDiamond.everyTimeFilterChangeFancy
      };
      case SET_SWITCHFILTER_REQUESTTAB:
        console.log('request',selectYourDiamond.everyTimeFilterChangeRequest);
        return {
          ...selectYourDiamond,
          RequestFilter: selectYourDiamond.everyTimeFilterChangeRequest
        };

        case SET_SAVEFILTER_REQUESTTAB:
          return {
            ...selectYourDiamond,
            everyTimeFilterChangeRequest: payload
          };
    
        case SET_SAVEEVERYTIME_REQUESTTAB:
          return {
            ...selectYourDiamond,
            everyTimeFilterChangeRequest: payload
          };

    default:
      return selectYourDiamond;
  }
}
