import React, { useEffect, useState, useLayoutEffect } from "react";
import { notification, Spin } from "antd";
import { useLocation } from "react-router-dom";

import { Tabs, Tab } from "react-bootstrap";
// import MultiRangeSlider from "../../contacts/MultiRangeSlider"

import SaveIcon from "../../assets/images/choose-setting/save__icon.svg";
import ResetIcon from "../../assets/images/choose-setting/reset__icon.svg";
import ChooseSettingItemDetails from "./ChooseSettingItemDetails";
import MinedDiamond from "./../common/SelectYourDiamond/MinedDiamond";

// import LabGrownDiamond from "../common/SelectYourDiamond/LabGrown";
import Compare from "../common/SelectYourDiamond/Compare";
import selectYourDiamondService from "../../services/select-your-diamond.service";
import { useSelector } from "react-redux";

import ViewDiamondDetails from "../common/SelectYourDiamond/ViewDiamondDetails";
import Request from "../common/SelectYourDiamond/Request";
import { useDispatch } from "react-redux";
import {
  handleResetMinedPage,
  handleResetLabPage,
  handleResetFancyPage,
  handleResetRequestPage,
  handleSetSaveSearchMinedTab,
  handleSetSaveFilterMinedTab,
  handleSetSwitchFilter,
  handleSetSaveSearchLabTab,
  handleSetSaveFilterLabTab,
  handleSetSwitchLabFilter,
  handleSetSaveSearchFancyTab,
  handleSetSaveFilterFancyTab,
  handleSetSwitchFancyFilter,
  handleSetSwitchRequestFilter,
  handleSetSaveSearchRequestTab,
} from "../../actions/selectYourDiamond/selectYourDiamond";
import { handleHideCompleteEarningYourStudPage } from "../../actions/chooseYourEarning/chooseYourEarningActions";
import { NotificationManager } from "react-notifications";
import SelectYourDiamondService from "../../services/select-your-diamond.service";
import styled from "styled-components";
import colorPanelService from "../../services/color-panel.service";
import { NavLink, useNavigate } from "react-router-dom";

const SelectYourDiamond = ({ props }) => {
  const CompareIds = useSelector(
    (state) => state.selectYourDiamondReducer.compareIds
  );
  const navigate = useNavigate();
  const pageNavigation = useSelector((state) => state.pageNavigationReducer);
  const [activeTab, setActiveTab] = useState("Standard");
  const [key, setKey] = useState("Standard");
  const [loading, setLoading] = useState(false);
  const [isMinedReset, setIsMinedReset] = useState(false);
  const [isMinedSaveSearch, setIsMinedSaveSearch] = useState(false);
  const [isLabGrownReset, setIsLabGrownReset] = useState(false);
  const [isLabGrownSaveSearch, setIsLabGrownSaveSearch] = useState(false);
  const [navigationData, setNavigationData] = useState([]);
  const dummyNavigationData = [
    {
      setting: "True",
      order: "1",
      name: "Request",
    },
    {
      setting: "True",
      order: "2",
      name: "Standard",
    },

    {
      setting: "True",
      order: "7",
      name: "Compare",
    },
  ];
  const [orderNumbers, setOrderNumbers] = useState([]);
  const [isAdvance, setIsAdvance] = useState(false);
  const [disable, setDisable] = useState(false);
  const [chooseItemPayLoad, setChooseItemPayLoad] = useState({});
  const [isStandardVisible, setIsStandardVisible] = useState(false);

  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const pageDetails = useSelector((state) => state.selectYourDiamondReducer);
  const dispatch = useDispatch();
  const search = useLocation().search;
  const dealerId = new URLSearchParams(search).get("DealerLink");
  const isReset = new URLSearchParams(search).get("ResetIFrame");
  const chooseYourSetting = useSelector(
    (state) => state.chooseYourSettingReducer
  );
  const isChange = new URLSearchParams(search).get("isChange");
  const tabkey = new URLSearchParams(search).get("tabkey");
  // Options

  const initialOptionsData = {
    studbuilderLinkURL: "",
    internalUseLink: true,
    showOnlyDiamondSearch: true,
    showDealerDiamondsSKU: true,
    showScheduleViewing: true,
    showAddress: true,
    showCallForPrice: false,
    applyGstTax: true,
    gstTaxValue: "",
    showPinterestShare: true,
    showTwitterShare: true,
    showFacebookShare: true,
    showFacebookLike: true,
    showAddToCartButton: true,
    ShoppingCartURL: "",
    showViewCartButton: true,
    ShowInstagramPlus: true,
  };

  const [myOptionsData, setMyOptionsData] = useState(initialOptionsData);

  const paramdealerId = new URLSearchParams(search).get("DealerLink");

  const isTab = new URLSearchParams(search).get("isTab");

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  useLayoutEffect(() => {
    // if (loginDetails) {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    }
    // }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "StudBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              var tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              //debugger;
              console.log(tempobj);
              console.log(initialColorObj);
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */

  // const handlTabChanges = (val) => {
  //   setKey(val);

  //   if (val == "Mined") {
  //     navigate("/Mined" + `?DealerLink=${dealerId}`);
  //   } else if (val == "Lab Grown") {
  //     navigate("/Lab" + `?DealerLink=${dealerId}`);
  //   } else if (val == "Request") {
  //     navigate("/Request" + "?DealerLink=720");
  //   } else if (val == "Compare") {
  //     var compareIds = CompareIds.toString();
  //     navigate("/Compare" + `?DealerLink=${dealerId}&CompareIds=${compareIds}`);
  //   } else {
  //     navigate("/Mined" + `?DealerLink=${dealerId}`);
  //   }
  //   // if (val !== "Compare") {
  //   //   dispatch(handleComaparePageIds(""));
  //   // }
  // };
  const handlTabChanges = (val) => {
    setKey(val);

    if (val == "Mined") {
      navigate(
        "/Mined" +
          `?DealerLink=${dealerId}&tabName=selectYourDiamond&tabkey=Standard`
      );
    } else if (val == "Lab Grown") {
      navigate("/Lab" + `?DealerLink=${dealerId}&tabName=selectYourDiamond`);
    } else if (val == "Request") {
      navigate(
        "/Request" +
          `?DealerLink=${dealerId}&tabName=selectYourDiamond&tabkey=Request`
      );
    } else if (val == "Compare") {
      var compareIds = CompareIds.toString();
      navigate("/Compare" + `?DealerLink=${dealerId}&CompareIds=${compareIds}`);
    } else {
      navigate(
        "/Mined" +
          `?DealerLink=${dealerId}&tabName=selectYourDiamond&tabkey=Standard`
      );
    }
    // if (val !== "Compare") {
    //   dispatch(handleComaparePageIds(""));
    // }
  };

  const handleGetstudBuilderOptiondetails = (id) => {
    let inputData = {
      dealerID: id,
    };

    try {
      SelectYourDiamondService.GetstudBuilderOptiondetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          if (message == "Success") {
            if (responseData) {
              if (response.data.responseData.length > 0) {
                setMyOptionsData((prevState) => ({
                  ...prevState,
                  studbuilderLinkURL: responseData.studBuilderURL,
                  internalUseLink: responseData.internalLinkSB,
                  showOnlyDiamondSearch: responseData.onlyDiamondSearch,
                  showDealerDiamondsSKU: responseData.stud_ShowSKU,
                  showScheduleViewing: responseData.showScheduleView,
                  showAddress: responseData.showAddresses,
                  showCallForPrice: responseData.callForPricestud,
                  applyGstTax: responseData.showGstPrice,
                  gstTaxValue: responseData.taxRate,
                  showPinterestShare: responseData.showPinterestShare,
                  showTwitterShare: responseData.showTwitterShare,
                  showFacebookShare: responseData.showFacebookShare,
                  showFacebookLike: responseData.showFacebookLike,
                  showAddToCartButton: responseData.showAddToCartButtonStud,
                  ShoppingCartURL: responseData.diamondstudshoppingurl,
                  showViewCartButton: responseData.showViewCartButtonStud,
                  ShowInstagramPlus: responseData.showInstagramShare,
                }));
              }
            }
          } else {
            console.log("Not Binded");
          }
        })
        .catch((error) => {
          console.log(error);
          setMyOptionsData((prevState) => ({
            showOnlyDiamondSearch: false,
          }));
        });
    } catch (error) {
      console.log(error);
      setMyOptionsData((prevState) => ({
        showOnlyDiamondSearch: false,
      }));
    }
  };

  const handleTabChange = (e) => {
    setActiveTab(e);
  };

  const handleReset = () => {
    if (activeTab == "Standard") {
      setIsMinedReset(true);
    } else {
      setIsLabGrownReset(true);
    }
  };

  const handleGetReset = (reset) => {
    if (activeTab == "Standard") {
      setIsMinedReset(reset);
    } else {
      setIsLabGrownReset(reset);
    }
  };

  const handleSaveSearch = () => {
    if (activeTab == "Standard") {
      setIsMinedSaveSearch(true);
    } else {
      setIsLabGrownSaveSearch(true);
    }
  };

  const handleGetSaveSearch = (saveSearch) => {
    if (activeTab == "Standard") {
      setIsMinedSaveSearch(saveSearch);
    } else {
      setIsLabGrownSaveSearch(saveSearch);
    }
  };

  useEffect(() => {
    if (paramdealerId) {
      handleGetNavigationData(paramdealerId);
      dispatch(handleHideCompleteEarningYourStudPage());
      setKey(tabkey);
    }
  }, []);

  useEffect(() => {
    if (chooseYourSetting) {
      if (chooseYourSetting.isViewCompleteEarningTwoDiamondDetails) {
        setDisable(chooseYourSetting.isViewCompleteEarningTwoDiamondDetails);
        // setChooseItemPayLoad(chooseYourSetting.selectedRow);
      }
    }
  }, [chooseYourSetting]);

  const handleGetNavigationData = async (id) => {
    let inputData = {
      dealerId: id.toString(),
    };

    try {
      await selectYourDiamondService
        .GetStudBuilderOpeningPage(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (responseData) {
            let navigationArray = [];
            if (responseData._AdvancedSearchData) {
              // navigationArray.push(responseData.advancedSearchData[0]);
              if (responseData._AdvancedSearchData[0].setting == "True") {
                setIsAdvance(true);
              }
            }
            if (responseData._LabData) {
              navigationArray.push(responseData._LabData[0]);
            }
            if (responseData._MinedData) {
              navigationArray.push(responseData._MinedData[0]);
              if (responseData._MinedData[0].setting == "True") {
                setIsStandardVisible(true);
              }
            }
            if (responseData._RequestDiamondData) {
              navigationArray.push(responseData._RequestDiamondData[0]);
            }
            if (responseData._CompareDiamondData) {
              navigationArray.push(responseData._CompareDiamondData[0]);
            }

            let newArray = [];
            for (let i = 0; i < navigationArray.length + 1; i++) {
              newArray.push((i + 1).toString());
            }

            // let newArray = navigationArray.map((item, index) => {
            //   return index.toString();
            // });

            setOrderNumbers(newArray);

            let newminArr = navigationArray
              .filter((x) => x.setting == "True")
              .map((x) => {
                return x.order;
              });
            console.log(newminArr);
            var val = Math.min(...newminArr);
            console.log(val);
            let newActiveTab = navigationArray
              .filter((item) => {
                return item.setting == "True" && item.order == val.toString();
              })[0]
              .name.toString();

            // setActiveTab(newActiveTab);
            // setActiveTab("Standard");
            // if (newActiveTab == "Mined") {
            //   navigate("/Mined" + `?DealerLink=${id}&isTab=True`);
            // } else if (newActiveTab == "Request") {
            //   navigate("/Request" + `?DealerLink=${id}&isTab=True`);
            // } else {
            //   navigate("/Mined" + `?DealerLink=${id}&isTab=True`);
            // }
            //debugger;
            if (isChange == "True") {
              //debugger;
              if (newActiveTab == "Mined") {
                navigate(
                  "/Mined" +
                    `?DealerLink=${id}&isTab=True&tabName=selectYourDiamond&tabkey=Standard`
                );
              } else if (newActiveTab == "Request") {
                navigate(
                  "/Request" +
                    `?DealerLink=${id}&isTab=True&tabName=selectYourDiamond&tabkey=Request`
                );
              } else {
                navigate(
                  "/Mined" +
                    `?DealerLink=${id}&isTab=True&tabName=selectYourDiamond&tabkey=Standard`
                );
              }
              setActiveTab(newActiveTab);
            } else {
              setKey(tabkey);
            }

            setNavigationData(navigationArray);
          }
          // setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
        });
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };
  function getTextStyle(name) {
    if(key){
      if (key == name) {
        return {
          color: "#fff",
          background: `${varColorObj.columnHeaderAccentColor}`,
        };
      }
    }
    
  }

  const handleResetForPage = () => {
    if (key == "Request") {
      dispatch(handleResetRequestPage());
      dispatch(handleSetSaveSearchRequestTab(false));
    } else {
      if (activeTab == "Standard") {
        console.log("RESETMINED");
        dispatch(handleResetMinedPage());
        dispatch(handleSetSaveSearchMinedTab(false));
        //dispatch(handleSetSaveFilterMinedTab(obj));
      }
      if (activeTab == "Lab") {
        console.log("RESETLAB");
        dispatch(handleResetLabPage());
        dispatch(handleSetSaveSearchLabTab(false));
        //dispatch(handleSetSaveFilterLabTab(obj));
      }
      if (activeTab == "FancyColor") {
        console.log("RESETFANCY");
        dispatch(handleResetFancyPage());
        dispatch(handleSetSaveSearchFancyTab(false));
        //dispatch(handleSetSaveFilterFancyTab(objFancy));
      }
      if (activeTab == "Request") {
        console.log("RESETREQUEST");
        dispatch(handleResetRequestPage());
      }
    }
    NotificationManager.success("Search Reset Successfully.");
  };

  const handleSaveSearchForPage = () => {
    if (key == "Request") {
      dispatch(handleSetSaveSearchRequestTab(true));
      dispatch(handleSetSwitchRequestFilter());
    } else {
      if (activeTab == "Standard") {
        console.log("RESETMINED");
        //setSaveSearchMined(true);
        dispatch(handleSetSaveSearchMinedTab(true));
        dispatch(handleSetSwitchFilter());
      }
      if (activeTab == "Lab") {
        console.log("RESETLAB");
        //setSaveSearchMined(true);
        dispatch(handleSetSaveSearchLabTab(true));
        dispatch(handleSetSwitchLabFilter());
      }
      if (activeTab == "") {
        console.log("RESETFANCY");
        //setSaveSearchMined(true);
        dispatch(handleSetSaveSearchFancyTab(true));
        dispatch(handleSetSwitchFancyFilter());
      }
    }
    NotificationManager.success("Search Saved Successfully.");
  };

  console.log(orderNumbers, "orderNumbers");
  console.log(navigationData, "navigationData");
  return (
    <React.Fragment>
      {/* {pageNavigation.viewPage == true ? (
        <div>
          <CompleteYourRing />
        </div>
      ) : ( */}
      {isTab != "false" ? (
        <div className="allprod_section jwlsrch__section app__preview choose__setting">
          <Spin spinning={loading || wrapperLoading}>
            <div className="row">
              <div className="col-md-12">
                <div className="prodetail__section">
                  <div className="col-md-12">
                    <div className="prod__maindiv app__preview p-0 border-0">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="prodesc__div">
                            <div className="tright__btns">
                              {key == "Compare" ? null : (
                                <p onClick={handleSaveSearchForPage}>
                                  <img />
                                  <span>Save Search</span>
                                </p>
                              )}
                              {key == "Compare" ? null : (
                                <p onClick={handleResetForPage}>
                                  <img src={ResetIcon} />
                                  <span>Reset</span>
                                </p>
                              )}
                            </div>
                            {/* {isTab != "false" ? ( */}
                            <div className="custom__tab">
                              <ul>
                                {orderNumbers.map((order) => {
                                  return navigationData.map((tab) => {
                                    return (
                                      tab.setting == "True" &&
                                      order == tab.order && (
                                        <li
                                          style={getTextStyle(tab.name)}
                                          onClick={() =>
                                            handlTabChanges(tab.name)
                                          }
                                          title={tab.name}
                                        >
                                          <div className="mr-2 nav-btn">
                                            {tab.name == "Compare"
                                              ? key == "Compare"
                                                ? "Compare"
                                                : "Compare" +
                                                  " " +
                                                  "(" +
                                                  CompareIds.length +
                                                  ")"
                                              : tab.name == "Fancy Colored"
                                              ? tab.name.split(/(?=[A-Z])/)[0] +
                                                " " +
                                                tab.name.split(/(?=[A-Z])/)[1]
                                              : tab.name}
                                          </div>
                                        </li>
                                      )
                                    );
                                  });
                                })}
                              </ul>
                            </div>
                            {/* ) : (
                              ""
                            )} */}

                            {/* <Tabs
                                activeKey={key}
                                defaultActiveKey={key}
                                id="uncontrolled-tab-example"
                                className="tab__div border-0"
                                onSelect={(k) => handlTabChanges(k)}
                              >
                                {orderNumbers.map((order) => {
                                  return navigationData.map((tab) => {
                                    return (
                                      tab.setting == "True" &&
                                      order == tab.order && (
                                        <div>
                                          <Tab
                                            eventKey={tab.name}
                                            title={tab.name}
                                          ></Tab>
                                        </div>
                                      )
                                    );
                                  });
                                })}
                              </Tabs> */}

                            {/* {key == "Mined" && <MinedTab />}
                              {key == "Lab Grown" && <LabGrown />}
                              {key == "Fancy Colored" && <Fancy />}
                              {key == "Request" && <Request />}
                              {key == "Compare" && <Compare />} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      ) : null}

      {/* )} */}
    </React.Fragment>
  );
};

export default SelectYourDiamond;
