import { httpApps,httpAdminCompanyLocation } from "../helper/http-common";

class ChooseSettingServices {
  GetLocationDetails(inputData) {
    return httpAdminCompanyLocation.post("/Profile/LocationLoadDetails",inputData)
}
  GetAllStudMount(inputData) {
    return httpApps.post("studBuilder/GetAllStudMount", inputData);
  }
  // GetFilterData(inputData) {
  //   return httpApps.post("RingBuilder/GetFilterData", inputData);
  // }
  LoadViewDiamonds(inputData) {
    return httpApps.post("studBuilder/LoadViewDiamonds", inputData);
  }
  GetDealerInformation(inputData) {
    return httpApps.post("DiamondLink/GetDealerInformation", inputData);
  }
  GetDealerLocationData(inputData) {
    return httpApps.post("DiamondLink/GetDealerLocationData", inputData);
  }
  BindTimeDropDown(inputData) {
    return httpApps.post("studBuilder/BindTimeDropDown", inputData);
  }
}

export default new ChooseSettingServices();
