import {
  VIEW_DETAILS_PAGE,
  HIDE_DETAILS_PAGE,
  VIEW_Complete_Earning_TwoDiamond_DETAILS_PAGE,
  VIEW_Complete_Earning_TwoDiamond_DETAILS_PAGE_Hide,
  VIEW_EDIT_SHOW,
  VIEW_EDIT_HIDE
} from "../../actions/chooseYourSetting/types";

const initialState = {
  isViewDetails: false,
  selectedRow: null,
  isViewCompleteEarningTwoDiamondDetails: false,
  isViewEditShow: false
};

export default function handleChooseYourSetting(
  chooseYourSetting = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case VIEW_DETAILS_PAGE:
      return {
        ...chooseYourSetting,
        isViewDetails: true,
        selectedRow: payload
      };
    case VIEW_EDIT_SHOW:
      return {
        ...chooseYourSetting,
        isViewEditShow: true
      };
    case VIEW_EDIT_HIDE:
      return {
        ...chooseYourSetting,
        isViewEditShow: false
      };
    case HIDE_DETAILS_PAGE:
      return {
        ...chooseYourSetting,
        isViewDetails: false,
        selectedRow: null
      };
    case VIEW_Complete_Earning_TwoDiamond_DETAILS_PAGE:
      return {
        ...chooseYourSetting,
        isViewCompleteEarningTwoDiamondDetails: true,
        selectedRow: payload
      };
    case VIEW_Complete_Earning_TwoDiamond_DETAILS_PAGE_Hide:
      return {
        ...chooseYourSetting,
        isViewCompleteEarningTwoDiamondDetails: false,
        selectedRow: null
      };

    default:
      return chooseYourSetting;
  }
}
