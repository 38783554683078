import {
  SET_ACTIVE_TAB,
  SWAP_TABS,
  ADD_SETTING_VIEW_EDIT,
  REMOVE_SETTING_VIEW_EDIT,
  ADD_DIAMOND_VIEW_EDIT,
  REMOVE_DIAMOND_VIEW_EDIT,
} from "./types";

export const handleActiveTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ACTIVE_TAB,
      payload: data,
    });
  } catch (error) { }
};

export const handleSwapTabs = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SWAP_TABS,
      payload: data,
    });
  } catch (error) { }
};

export const handleAddSettingViewEdit = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SETTING_VIEW_EDIT,
    });
  } catch (error) { }
};

export const handleAddDiamondViewEdit = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_DIAMOND_VIEW_EDIT,
    });
  } catch (error) { }
};

export const handleRemoveSettingViewEdit = (data) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_SETTING_VIEW_EDIT,
    });
  } catch (error) { }
};

export const handleRemoveDiamondViewEdit = (data) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_DIAMOND_VIEW_EDIT,
    });
  } catch (error) { }
};
