import React, { useState, useEffect, Fragment, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Tooltip,
  Select,
  Table,
  Slider,
  Checkbox,
  Spin,
  Pagination,
  Modal,
  Button,
  Empty,
  Input,
} from "antd";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import roundDiamond from "../../../assets/images/daimond-link.jpg";

import Round from "../../../assets/images/choose-setting/round.svg";
import Radiant from "../../../assets/images/choose-setting/Radiant.svg";
import Asscher from "../../../assets/images/choose-setting/Asscher.svg";
import Cushion from "../../../assets/images/choose-setting/Cushion.svg";
import Emerald from "../../../assets/images/choose-setting/Emerald.svg";
import Heart from "../../../assets/images/choose-setting/Heart.svg";
import Marquise from "../../../assets/images/choose-setting/Marquise.svg";
import Oval from "../../../assets/images/choose-setting/Oval.svg";
import Princess from "../../../assets/images/choose-setting/Princess.svg";
import Pear from "../../../assets/images/choose-setting/Radiant.svg";
import Info_icon from "../../../assets/images/info_icon.png";
import Video_icon from "../../../assets/images/icons/video_icon.svg";
import Close from "../../../assets/images/close.png";
import Eye_icon from "../../../assets/images/eye__icon.svg";
import Rectangle from "../../../assets/images/Rectangle.png";
// import {
//   handleComaparePageIds,
//   handleSetDiamondDetails,
// } from "../../../actions/selectyourdiamond/selectYourDiamond";
import { handleViewDetailsPage , handleSetSaveFilterRequestTab ,handleSetSaveEveryTimeRequestTab } from "../../../actions/selectYourDiamond/selectYourDiamond";
import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import styled from "styled-components";
import colorPanelService from "../../../services/color-panel.service";
import { NavLink, useNavigate } from "react-router-dom";

const Request = ({}) => {
  const { Search } = Input;
  const resetVal = useSelector((state) => state.selectYourDiamondReducer);
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const [isAdvance, setIsAdvance] = useState(false);
  const dispatch = useDispatch();
  const [productChange, setProductChange] = useState({});
  const [viewItem, setViewItem] = useState("list");
  const [hideShowFilter, setHideShowFilter] = useState(true);
  const [priceValue, setPriceValue] = useState([0, 9999999]);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [caratSlideValue, setCaratSlideValue] = useState([0, 22]);
  const [loading, setLoading] = useState(false);
  const [filterloading, setFilterLoading] = useState(false);
  const [reqDiamondloading, setReqDiamondLoading] = useState(false);
  const [minedList, setMinedList] = useState([]);
  const [isVideo, setIsVideo] = useState(false);
  const [videoImageModal, setVideoImageModal] = useState(false);
  const [selectedRowDetails, setSelectedRowDetails] = useState({});
  const [searchSku, setSearchSku] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  const [state, setState] = useState({
    productChange: {},
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    Certificates: [],
  });
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [diamondFormData, setDiamondFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    comments: "",
  });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalRowCount, setTotalRowCount] = useState(0);
  const [dealerId, setDealerId] = useState();
  const [rowDetails, setRowDetails] = useState({});
  const [filterVals, setFilterVals] = useState({
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    Certificates: [],
  });

  // const [varColorObj, setVarColorObj] = useState({
  //   callToActionColor: "",
  //   hoverColor: "",
  //   columnHeaderAccentColor: "",
  //   linkColor: "",
  // });
  // const [toggle, setToggle] = useState(true);
  // const previewcolor = useSelector((state) => state.previewColorReducer);
  const [name, setName] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const [validations, setValidations] = useState({
    firstNameVal: "",
    lastNameVal: "",
    phoneNumberVal: "",
    emailAddressVal: "",
    commentsVal: "",
  });

  // useEffect(() => {
  //   if (previewcolor) {
  //     if (previewcolor.buttonColor) {
  //       setVarColorObj({
  //         ...varColorObj,
  //         callToActionColor: previewcolor.buttonColor.callToActionColor,
  //         hoverColor: previewcolor.buttonColor.hoverColor,
  //         columnHeaderAccentColor:
  //           previewcolor.buttonColor.columnHeaderAccentColor,
  //         linkColor: previewcolor.buttonColor.linkColor,
  //       });

  //       setToggle(previewcolor.toogle);
  //     }
  //   }
  // }, [previewcolor.toogle, toggle]);

  // CSS Variables for Color panel

  // var primaryBtn = {
  //   backgroundColor: varColorObj.callToActionColor,
  //   borderBottomLeftRadius: "5px",
  //   borderBottomRightRadius: "5px",
  //   borderTopLeftRadius: "5px",
  //   borderTopRightRadius: "5px",
  //   color: "#fff",
  //   border: "none",
  //   padding: "0px 10px",
  //   height: "35px",
  // };

  // var primaryBtnHover = {
  //   backgroundColor: varColorObj.hoverColor,
  //   borderBottomLeftRadius: "5px",
  //   borderBottomRightRadius: "5px",
  //   borderTopLeftRadius: "5px",
  //   borderTopRightRadius: "5px",
  //   color: "#fff",
  //   border: "none",
  //   padding: "0px 10px",
  //   height: "35px",
  // };

  const handleMouseEnter = (name) => {
    setIsHovering(true);
    setName(name);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    setName("");
  };

  // useEffect(() => {
  //   setState({
  //     PriceMin: 0,
  //     PriceMax: 9999999,
  //     CaratMin: 0,
  //     CaratMax: 22,
  //     Shape: [],
  //     Cut: [],
  //     Color: [],
  //     Polish: [],
  //     Fluorescence: [],
  //     Symmetry: [],
  //     productChange: {},
  //   });
  //   setPriceValue([0, 9999999]);
  //   setCaratSlideValue([0, 22]);
  // }, [resetVal.resetMined]);
  const search = useLocation().search;
  const paramdealerId = new URLSearchParams(search).get("DealerLink");

  const [showAllContent , setShowAllContent] =  useState(true)

  useEffect(() => {
    if (paramdealerId) {
      handleGetDiamondFilters(paramdealerId, pageNo, pageSize,resetVal.saveSearchRequest);
      setDealerId(paramdealerId);
    }
  }, [resetVal.resetRequest, resetVal.saveSearchRequest]);

  const handleOnChange = (row, e) => {
    const temp = minedList.map((x) => {
      if (x.intDiamondId == row.intDiamondId) {
        return { ...x, checkbox: e.target.checked == true ? true : false };
      } else return x;
    });

    let saveRow = temp.filter((obj) => obj.checkbox == true);
    setMinedList(temp);
    var CompareIds = saveRow.map((x) => x.intDiamondId);
    // dispatch(handleComaparePageIds(CompareIds));
  };

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  useLayoutEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "StudBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              var tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              //debugger;
              console.log(tempobj);
              console.log(initialColorObj);
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */

  const columns = [
    {
      title: "",
      dataIndex: "checkbox",
      width: 30,
      render: (item, row) => {
        return (
          <div
            className="action__btns"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Checkbox
              name="checkbox"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.checkbox == true ? true : false}
              onChange={(e) => handleOnChange(row, e, "checkbox")}
            />
          </div>
        );
      },
    },

    {
      title: "Shape",
      dataIndex: "txtShape",

      render: (item, row) => {
        return (
          <div>
            <img
              style={{ width: "40px", height: "40px" }}
              src={row.imageFileName}
              alt=""
            />
            <span>{row.txtShape}</span>
          </div>
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "intDiamondId",
    },
    {
      title: "Carat",
      dataIndex: "fltCarat",
    },
    {
      title: "Color",
      dataIndex: "txtColor",
    },
    {
      title: "Clarity",
      dataIndex: "txtClarity",
    },
    // {
    //   title: "Cut",
    //   dataIndex: "txtCutGrade",
    // },
    {
      title: "In House",
      dataIndex: "txtInhouse",
      width: 150,
    },
    {
      title: "Depth",
      dataIndex: "fltDepth",
    },
    {
      title: "Table",
      dataIndex: "fltTable",
    },
    {
      title: "Polish",
      dataIndex: "txtPolish",
    },
    {
      title: "Measurement",
      dataIndex: "txtMeasurements",
    },
    {
      title: "Cert",
      dataIndex: "txtCertificate",
    },
    {
      title: "Price",
      dataIndex: "realPrice",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (index, row) => (
        <div>
          <div className="action__btns">
            {/* <div className="image__block">
              <img src={Info_icon} alt="" />
            </div>
            <div className="image__block">
              <img
                src={Video_icon}
                alt=""
              />
            </div> */}
            <div className="image__block">
              <img
                src={Eye_icon}
                alt=""
                onClick={() => handleViewDetails(row)}
              />
            </div>
          </div>
          <div className="info__tooltip d-none">
            <div className="close__div">
              <img src={Close} alt="" />
            </div>
            <p>
              <span>Depth</span>
              <span>100</span>
            </p>
            <p>
              <span>Table</span>
              <span>100</span>
            </p>
            <p>
              <span>Polish</span>
              <span>Very Good</span>
            </p>
            <p>
              <span>Symmetry</span>
              <span>Very Good</span>
            </p>
            <p>
              <span>Measurement</span>
              <span>9.80X7.56X</span>
            </p>
          </div>
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" width="50px" />,
      Shape: <span>Oval</span>,
      SKU: <span>176152702</span>,
      Carat: <span>2.53</span>,
      Color: <span>G</span>,
      Clarity: <span>VVS2</span>,
      Cut: <span>Excellent</span>,
      InHouse: <span>Yes</span>,
      Depth: <span>60.2</span>,
      Table: <span>58</span>,
      Polish: <span>Excellent</span>,
      Measurement: <span>10.35X7.84X4.80</span>,
      Cert: <span>GIA</span>,
      Price: <span>$190.00</span>,
    },
  ];

  const { Option } = Select;

  const handleProductChangeOpen = (intDiamondId) => {
    var newproductChange = productChange;
    newproductChange[intDiamondId] = true;
    setProductChange((prevState) => ({
      ...prevState,
      ...newproductChange,
    }));
  };

  const handleProductChangeClose = (intDiamondId) => {
    var newproductChange = productChange;
    newproductChange[intDiamondId] = false;
    setProductChange((prevState) => ({
      ...prevState,
      ...newproductChange,
    }));
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };

  const handleViewDetails = (details) => {
    dispatch(handleViewDetailsPage());
    // dispatch(handleSetDiamondDetails(details));
  };
  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const firstSliderPriceValue = (e, num) => {
    setIsFilter(true);
    let newArr = [...priceValue];
    var StrValue = e.target.value && e.target.value.replace(currencySymbol, "");
    var Value = StrValue.replace(/,/g, "");

    if (Number(parseFloat(Value.replace(/,/g, ""))) > filterVals.PriceMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
        var tempArray = filterVals;
        tempArray["PriceMin"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      }
      // else {
      //   newArr[0] = Number(parseFloat(Value.replace(/,/g, "")));
      //   setPriceValue(newArr);
      // }
      else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[0] = Value;
            setPriceValue(newArr);
          }
        }
        var tempArray = filterVals;
        tempArray["PriceMin"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
        var tempArray = filterVals;
        tempArray["PriceMax"] = 0;
        dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      }
      // else {
      //   newArr[1] = Number(parseFloat(Value.replace(/,/g, "")));
      //   setPriceValue(newArr);
      // }
      else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[1] = Value;
            setPriceValue(newArr);
          }
        }
        var tempArray = filterVals;
        tempArray["PriceMax"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      }
    }
  };

  const firstsetCaratSlideValue = (e, num) => {
    setIsFilter(true);
    let newArr = [...caratSlideValue];
    var StrValue = e.target.value && e.target.value.replace("$", "");
    var Value = StrValue.replace(/,/g, "");
    if (
      Number(parseFloat(e.target.value.replace(/,/g, ""))) > filterVals.CaratMax
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setCaratSlideValue(newArr);
        var tempArray = filterVals;
        tempArray["CaratMin"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeRequestTab(tempArray));

      }
      // else {
      //   newArr[0] = Number(parseFloat(e.target.value.replace(/,/g, "")));
      //   setCaratSlideValue(newArr);
      // }
      else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[0] = Value;
            setCaratSlideValue(newArr);
          }
        }
        var tempArray = filterVals;
        tempArray["CaratMin"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setCaratSlideValue(newArr);
        var tempArray = filterVals;
        tempArray["CaratMax"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeRequestTab(tempArray));

      }
      // else {
      //   newArr[1] = Number(parseFloat(e.target.value.replace(/,/g, "")));
      //   setCaratSlideValue(newArr);
      // }
      else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[1] = Value;
            setCaratSlideValue(newArr);
          }
        }
        var tempArray = filterVals;
        tempArray["CaratMax"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
      }
    }
  };
  function removeSpecialCharactersAndConvertToNumber(inputString) {
    const cleanedString = inputString.replace(/[^0-9.]/g, '')
    // Remove a trailing hyphen, if present
    const cleanedStringWithoutHyphen = cleanedString.replace(/-$/, '');
    // Convert the cleaned string to a number
    return parseFloat(cleanedStringWithoutHyphen, 10);
  }

  const handleLabGrownFilterChange = (val, name, type) => {
    debugger
    setIsFilter(true);
    if (type == "filter") {
      let tempArray = filterVals[name];
      if (tempArray.includes(val)) {
        tempArray.pop(val);
      } else {
        tempArray.push(val);
      }
      setFilterVals({
        ...filterVals,
        [name]: tempArray,
      });
    } else {
      if(name == "DepthMin" || name == "DepthMax"|| name == "TableMin"|| name == "TableMax"){
        if(val > 100 || val < 0){
          return false
        }
        setFilterVals({
            ...filterVals,
            [name]:  removeSpecialCharactersAndConvertToNumber(val),
          });
        }else{
          setFilterVals({
            ...filterVals,
            [name]:  val,
          });
        }
    }
    let saveFilters = filterVals
    if(name == "Certificates"){
      saveFilters[name] = val
    }
    dispatch(handleSetSaveEveryTimeRequestTab(saveFilters));
  };

  const handleAfterChange = e => {
    var tempArray = filterVals;
    tempArray["CaratMin"] = e[0];
    tempArray["CaratMax"] = e[1];
    setFilterVals(tempArray);
    dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
  };

  const handleAfterChangePrice = e => {
    var tempArray = filterVals;
    tempArray["PriceMin"] = e[0];
    tempArray["PriceMax"] = e[1];
    setFilterVals(tempArray);
    dispatch(handleSetSaveEveryTimeRequestTab(tempArray));
  };

  const handleInputDiamondDataChange = (e, name) => {
    setDiamondFormData({
      ...diamondFormData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "firstName") {
      setValidations((prevdata) => ({
        ...prevdata,
        firstNameVal: "",
      }));
    }
    if (e.target.name == "lastName") {
      setValidations((prevdata) => ({
        ...prevdata,
        lastNameVal: "",
      }));
    }
    if (e.target.name == "phoneNumber") {
      setValidations((prevdata) => ({
        ...prevdata,
        phoneNumberVal: "",
      }));
    }
    if (e.target.name == "emailAddress") {
      setValidations((prevdata) => ({
        ...prevdata,
        emailAddressVal: "",
      }));
    }
    if (e.target.name == "comments") {
      setValidations((prevdata) => ({
        ...prevdata,
        commentsVal: "",
      }));
    }
  };

  const handleInputDiamondDataReset = () => {
    // handleInputDiamondDataRequest();
    setDiamondFormData({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
      comments: "",
    });
    setValidations({
      firstNameVal: "",
      lastNameVal: "",
      phoneNumberVal: "",
      emailAddressVal: "",
      commentsVal: "",
    });
  };

  const handleValidation = () => {
    const { firstName, lastName, phoneNumber, emailAddress, comments } =
      diamondFormData;
    const validations = {
      firstNameVal: "",
      lastNameVal: "",
      phoneNumberVal: "",
      emailAddressVal: "",
      commentsVal: "",
    };
    let isValid = true;

    if (!firstName.trim()) {
      isValid = false;
      validations.firstNameVal = "First Name is Compulsory";
    }
    if (!lastName.trim()) {
      isValid = false;
      validations.lastNameVal = "Last Name is Compulsory";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      validations.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      validations.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      validations.phoneNumberVal = "Phone number is invalid";
    } else {
      validations.phoneNumberVal = "";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!emailAddress.trim()) {
      isValid = false;
      validations.emailAddressVal = "Email address is compulsory";
    } else if (!emailAddress.match(validRegex)) {
      isValid = false;
      validations.emailAddressVal = "Email address is invalid";
    } else {
      validations.emailAddressVal = "";
    }
    if (!comments.trim()) {
      isValid = false;
      validations.commentsVal = "Comments is Compulsory";
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const handleInputDiamondDataRequest = () => {
    let arrShapes =
      filterVals.Shape.length > 0 ? filterVals.Shape.map((x) => x) : null;
    let arrCutGrades =
      state.Cut.length > 0 ? state.Cut.map((x) => Number(x.value)) : null;
    let arrColors =
      state.Color.length > 0 ? state.Color.map((x) => Number(x.value)) : null;
    let arrPolishs =
      state.Polish.length > 0 ? state.Polish.map((x) => Number(x.value)) : null;
    let arrClaritys =
      state.Clarity.length > 0
        ? state.Clarity.map((x) => Number(x.value))
        : null;
    let arrSymmetrys =
      state.Symmetry.length > 0
        ? state.Symmetry.map((x) => Number(x.value))
        : null;
    let arrFluorescences =
      state.Fluorescence.length > 0
        ? state.Fluorescence.map((x) => Number(x.value))
        : null;

    const isValid = handleValidation();

    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        firstName: diamondFormData.firstName,
        lastName: diamondFormData.lastName,
        phoneNumber: diamondFormData.phoneNumber,
        emailAddress: diamondFormData.emailAddress,
        comments: diamondFormData.comments,
        intDealerID: dealerId.toString(),
        subject: "",
        shapeList: arrShapes !== null ? arrShapes.toString() : "",
        // priceMin: filterVals.PriceMin.toString(),
        // priceMax: filterVals.PriceMax.toString(),
        // caratMin: filterVals.CaratMin.toString(),
        // caratMax: filterVals.CaratMax.toString(),
        priceMin: priceValue[0].toString(),
        priceMax: priceValue[1].toString(),
        caratMin: caratSlideValue[0].toString(),
        caratMax: caratSlideValue[1].toString(),
        colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",
        colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
        clarityMin:
          arrClaritys !== null ? Math.min(...arrClaritys).toString() : "",
        clarityMax:
          arrClaritys !== null ? Math.max(...arrClaritys).toString() : "",
        cutgradeMin:
          arrCutGrades !== null ? Math.min(...arrCutGrades).toString() : "",
        cutgradeMax:
          arrCutGrades !== null ? Math.max(...arrCutGrades).toString() : "",
        certificate: filterVals?.Certificates.toString(), //Certificate
        depthMin: filterVals.DepthMin.toString(),
        depthMax: filterVals.DepthMax.toString(),
        tableMin: filterVals.TableMin.toString(),
        tableMax: filterVals.TableMax.toString(),
        polishList:
          filterVals.Polish.length > 0 ? filterVals.Polish.toString() : "",
        symmetryList:
          filterVals.Symmetry.length > 0 ? filterVals.Symmetry.toString() : "",
        fluorescenceList:
          filterVals.Fluorescence.length > 0
            ? filterVals.Fluorescence.toString()
            : "",
        cutGradeList:
          filterVals.Cut.length > 0 ? filterVals.Cut.toString() : "",
        colorList:
          filterVals.Color.length > 0 ? filterVals.Color.toString() : "",
        clarityList:
          filterVals.Clarity.length > 0 ? filterVals.Clarity.toString() : "",
      };
      setReqDiamondLoading(true);
      SelectYourDiamondService.SendMailToRetailerForNoResults(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setReqDiamondLoading(false);
            setDiamondFormData({
              firstName: "",
              lastName: "",
              phoneNumber: "",
              emailAddress: "",
              comments: "",
            });
          } else {
            setReqDiamondLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setReqDiamondLoading(false);
        });
    } catch (error) {
      console.log(error);
      setReqDiamondLoading(false);
    }
  };
  const handleGetDiamondFilters = (id, pageNo, pageSize,val) => {
    try {
      let inputData = {
        dealerID: id.toString(),
      };
      setFilterLoading(true);
      SelectYourDiamondService.GetAvailableStudbuilderFilters(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData =
            response.data.responseData.availableFilters_Response;
          var tempAvailableFilterData = state;
          var tempAvailableFilterValData = filterVals;
          if (message == "Success") {
            if (responseData) {
              // tempAvailableFilterData.PriceMin = Number(responseData.priceMin);
              // tempAvailableFilterData.PriceMax = Number(responseData.priceMax);
              // tempAvailableFilterData.CaratMin = Number(responseData.caratMin);
              // tempAvailableFilterData.CaratMax = Number(responseData.caratMax);
              setIsAdvance(responseData.advanced);
              setCurrencySymbol(responseData.currencySign);
              var shapData = [];

              shapData = responseData.studBuilderSettingsShape.filter(
                (x) => x.id !== 0
              );
              tempAvailableFilterData.Shape = shapData;
              // tempAvailableFilterData.Shape =
              //   responseData.ringBuilderSettingsShape;
              tempAvailableFilterData.Cut =
                responseData.ringBuildersettingsCuts;
              tempAvailableFilterData.Color =
                responseData.ringBuildersettingsColor;
              tempAvailableFilterData.Clarity =
                responseData.ringBuildersettingsClarity;
              tempAvailableFilterData.Polish =
                responseData.ringBuildersettingsPolish;
              tempAvailableFilterData.Fluorescence =
                responseData.ringBuildersettingsFluorescence;
              tempAvailableFilterData.Symmetry =
                responseData.ringBuildersettingsSymmetry;
              tempAvailableFilterData.Certificates =
                responseData.ringBuildersettingsCertificates;
              tempAvailableFilterData.Clarity =
                responseData.ringBuildersettingsClarity;
              // tempAvailableFilterData.TableMin = responseData.tableMin;
              // tempAvailableFilterData.TableMax = responseData.tableMax;
              // tempAvailableFilterData.DepthMin = responseData.depthMin;
              // tempAvailableFilterData.DepthMax = responseData.depthMax;

              // tempAvailableFilterValData.PriceMin = Number(
              //   responseData.priceMin
              // );
              // tempAvailableFilterValData.PriceMax = Number(
              //   responseData.priceMax
              // );
              // tempAvailableFilterValData.CaratMin = Number(
              //   responseData.caratMin
              // );
              // tempAvailableFilterValData.CaratMax = Number(
              //   responseData.caratMax
              // );
              // tempAvailableFilterValData.TableMin = responseData.tableMin;
              // tempAvailableFilterValData.TableMax = responseData.tableMax;
              // tempAvailableFilterValData.DepthMin = responseData.depthMin;
              // tempAvailableFilterValData.DepthMax = responseData.depthMax;
              setState({
                ...state,
                ...tempAvailableFilterData,
              });
              var saveSearchObj = resetVal.RequestFilter;
              if (val == true) {
                setFilterVals(saveSearchObj);
                setPriceValue([saveSearchObj.PriceMin, saveSearchObj.PriceMax]);
                setCaratSlideValue([
                  saveSearchObj.CaratMin,
                  saveSearchObj.CaratMax
                ]);
              }else{
                setFilterVals({
                  ...filterVals,
                  ...tempAvailableFilterValData
                });
                var tempObj = tempAvailableFilterValData;
                tempAvailableFilterValData.Shape = [];
                tempAvailableFilterValData.Cut = [];
                tempAvailableFilterValData.Color = [];
                tempAvailableFilterValData.Clarity = [];
                tempAvailableFilterValData.Polish = [];
                tempAvailableFilterValData.Fluorescence = [];
                tempAvailableFilterValData.Symmetry = [];
                tempAvailableFilterValData.Intensity = [];
                tempAvailableFilterValData.Certificates = [];
                tempAvailableFilterValData.PriceMin = 0;
                tempAvailableFilterValData.PriceMax = 9999999;
                tempAvailableFilterValData.CaratMin = 0;
                tempAvailableFilterValData.CaratMax = 22;
                tempAvailableFilterValData.TableMin = 0;
                tempAvailableFilterValData.TableMax = 100;
                tempAvailableFilterValData.DepthMin = 0;
                tempAvailableFilterValData.DepthMax = 100;
                setPriceValue([0, 9999999]);
                setCaratSlideValue([0, 22]);
                tempObj["disabled"] = {};
                setFilterVals({
                  ...filterVals,
                  ...tempObj
                });
                dispatch(handleSetSaveFilterRequestTab(tempObj));
              }
              setFilterLoading(false);
            } else {
            }
          } else {
            setMinedList([]);
            setTotalRowCount(0);
            setFilterLoading(false);
          }
          setShowAllContent(false)
        })
        .catch((error) => {
          console.log(error);
          setFilterLoading(false);
          setShowAllContent(false)
        });
    } catch (error) {
      console.log(error);
      setFilterLoading(false);
      setShowAllContent(false)
    }
  };

  const handleGetMinedDiamondList = (
    id,
    PageNo,
    PageSize,
    shapes,
    priceMin,
    priceMax,
    caratmin,
    caratmax,
    allColor,
    allClarity,
    allCut,
    allPolish,
    allSymmetry,
    allFluorescence,
    depthmin,
    depthmax,
    tablemin,
    tablemax,
    Certificate, // Certificate
    Sku, //Sku
    colorVal, // color
    clarityVal, // clarity
    cutVal, // cut
    polishVal, // polish
    symmetryVal, //symmetry
    fluorescenceVal, //fluorescence
    filter // isFilter
  ) => {
    let arrShapes = shapes.length > 0 ? shapes.map((x) => x) : null;
    let arrCutGrades =
      allCut && allCut.length > 0 ? allCut.map((x) => Number(x.value)) : null;
    let arrColors =
      allColor && allColor.length > 0
        ? allColor.map((x) => Number(x.value))
        : null;
    let arrPolishs =
      allPolish && allPolish.length > 0
        ? allPolish.map((x) => Number(x.value))
        : null;
    let arrClaritys =
      allClarity && allClarity.length > 0
        ? allClarity.map((x) => Number(x.value))
        : null;
    let arrSymmetrys =
      allSymmetry && allSymmetry.length > 0
        ? allSymmetry.map((x) => Number(x.value))
        : null;
    let arrFluorescences =
      allFluorescence && allFluorescence.length > 0
        ? allFluorescence.map((x) => Number(x.value))
        : null;

    try {
      let inputData = {
        dealerId: id.toString(),
        strCheckFilter: filter ? "Filter" : "DealerID",
        shapeList: arrShapes !== null ? arrShapes.toString() : "",
        priceMin: priceMin.toString(),
        priceMax: priceMax.toString(),
        caratMin: caratmin.toString(),
        caratMax: caratmax.toString(),
        colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
        colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",
        clarityMin:
          arrClaritys !== null && arrClaritys.length
            ? Math.min(...arrClaritys).toString()
            : "",
        clarityMax:
          arrClaritys !== null && arrClaritys.length
            ? Math.max(...arrClaritys).toString()
            : "",
        cutGradeMin:
          arrCutGrades !== null && arrCutGrades
            ? Math.min(...arrCutGrades).toString()
            : "",
        cutGradeMax:
          arrCutGrades !== null && arrCutGrades
            ? Math.max(...arrCutGrades).toString()
            : "",
        symmetryMin:
          arrSymmetrys !== null && arrSymmetrys
            ? Math.min(...arrSymmetrys).toString()
            : "",
        symmetryMax:
          arrSymmetrys !== null && arrSymmetrys
            ? Math.max(...arrSymmetrys).toString()
            : "",
        symmetryList:
          symmetryVal && symmetryVal.length > 0 ? symmetryVal.toString() : "",
        polishMin:
          arrPolishs !== null && arrPolishs
            ? Math.min(...arrPolishs).toString()
            : "",
        polishMax:
          arrPolishs !== null && arrPolishs
            ? Math.max(...arrPolishs).toString()
            : "",
        polishList:
          polishVal && polishVal.length > 0 ? polishVal.toString() : "",
        fluorescenceMin:
          arrFluorescences !== null && arrFluorescences
            ? Math.min(...arrFluorescences).toString()
            : "",
        fluorescenceMax:
          arrFluorescences !== null && arrFluorescences
            ? Math.max(...arrFluorescences).toString()
            : "",
        fluorescenceList:
          fluorescenceVal && fluorescenceVal.length > 0
            ? fluorescenceVal.toString()
            : "",
        certificate:
          Certificate && Certificate.length > 0 ? Certificate.toString() : "",
        skuNo: Sku.toString(),
        pairCode: "",
        detailsLink: "",
        did: "",
        depthMin: depthmin ? depthmin.toString() : "",
        depthMax: depthmax ? depthmax.toString() : "",
        tableMin: tablemin ? tablemin.toString() : "",
        tableMax: tablemax ? tablemax.toString() : "",
        caratPriceMin: "",
        caratPriceMax: "",
        themeName: "",
        showPrice: "",
        bindWhat: "",
        cutGradeList: cutVal && cutVal.length > 0 ? cutVal.toString() : "",
        colorList: colorVal && colorVal.length > 0 ? colorVal.toString() : "",
        clarityList:
          clarityVal && clarityVal.length > 0 ? clarityVal.toString() : "",
        bindCheckbox: "",
        sOrigin: "",
        isDiamond: "",
        custID: "",
        countryTaxRate: "",
        countryCode: "",
        track: "",
        isFB: "",
        isMLRB: "",
        settingCenterStonCaratMin: "",
        settingCenterStonCaratMax: "",
        selectedDealerConunt: 0,
        strSelectedDealers: "",
        extraQStr: "",
        pageSize: PageSize.toString(),
        pageNumber: PageNo.toString(),
        orderBy: "FltPrice",
        orderType: "ASC",
        strDInventoryId: "",
        flagDD: "",
        ratioMin: "",
        ratioMax: "",
        invenID: "",
        fancyColor: "",
        intIntensity: 0,
        overtone: "",
        overtone2: "",
        country: "",
        shade: "",
        treatment: "",
        keytoSymbol: "",
        fluorescenceColor: "",
        blackInclusion: "",
        centralInclusion: "",
        milkyInclusion: "",
        eyeclean: "",
        ratioType: "",
        permission: "",
        video: "",
        strCertSolrQuery: "",
        strDetailLinkURL: "",
        pageName: "",
      };
      // let inputData = {
      //   dealerId: id.toString(),
      //   strCheckFilter: "DealerID",
      //   shapeList: arrShapes !== null ? arrShapes.toString() : "",
      //   priceMin: priceMin.toString(),
      //   priceMax: priceMax.toString(),
      //   caratMin: caratmin.toString(),
      //   caratMax: caratmax.toString(),
      //   colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",
      //   colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
      //   clarityMin:
      //     arrClaritys !== null ? Math.min(...arrClaritys).toString() : "",
      //   clarityMax:
      //     arrClaritys !== null ? Math.max(...arrClaritys).toString() : "",
      //   cutGradeMin:
      //     arrCutGrades !== null ? Math.min(...arrCutGrades).toString() : "",
      //   cutGradeMax:
      //     arrCutGrades !== null ? Math.max(...arrCutGrades).toString() : "",
      //   symmetryMin:
      //     arrSymmetrys !== null ? Math.min(...arrSymmetrys).toString() : 0,
      //   symmetryMax:
      //     arrSymmetrys !== null ? Math.max(...arrSymmetrys).toString() : 0,
      //   polishMin: arrPolishs !== null ? Math.min(...arrPolishs).toString() : 0,
      //   polishMax: arrPolishs !== null ? Math.max(...arrPolishs).toString() : 0,
      //   fluorescenceMin:
      //     arrFluorescences !== null
      //       ? Math.min(...arrFluorescences).toString()
      //       : 0,
      //   fluorescenceMax:
      //     arrFluorescences !== null
      //       ? Math.max(...arrFluorescences).toString()
      //       : 0,
      //   certificate: Certificate,
      //   skuNo: Sku.toString(),
      //   pairCode: "1",
      //   detailsLink: "DiamondDetail.aspx",
      //   pageSize: PageSize.toString(),
      //   currentPage: PageNo.toString(),
      //   orderBy: "SortOrder",
      //   orderType: "Asc",
      //   did: "",
      //   depthMin: depthmin.toString(),
      //   depthMax: depthmax.toString(),
      //   tableMin: tablemin.toString(),
      //   tableMax: tablemax.toString(),
      //   caratPriceMin: "0",
      //   caratPriceMax: "100000",
      //   themeName: "whiteTheme",
      //   showPrice: "1",
      //   bindWhat: "All",
      //   polishList: polishVal.length > 0 ? polishVal.toString() : "",
      //   symmetryList: symmetryVal.length > 0 ? symmetryVal.toString() : "",
      //   fluorescenceList:
      //     fluorescenceVal.length > 0 ? fluorescenceVal.toString() : "",
      //   extraQStr: "",
      //   cutGradeList: cutVal.length > 0 ? cutVal.toString() : "",
      //   colorList: colorVal.length > 0 ? colorVal.toString() : "",
      //   clarityList: clarityVal.length > 0 ? clarityVal.toString() : "",
      //   bindCheckbox: "1",
      //   sOrigin: "",
      //   isDiamond: "0",
      //   custID: "",
      //   countryTaxRate: "",
      //    skuNo: "",
      //   vendorID: "",
      //   countryCode: "",
      //   track: "",
      //   isFB: "",
      //   isMLRB: "",
      //   settingCenterStonCaratMin: "",
      //   settingCenterStonCaratMax: "",
      //   selectedDealerConunt: 0,
      //   strSelectedDealers: "",
      //   extraQStr: "",
      //   pageSize: "10",
      //   pageNumber: "1",
      //   orderBy: "FltPrice",
      //   orderType: "ASC",
      //   strDInventoryId: "",
      //   flagDD: "",
      //   ratioMin: "",
      //   ratioMax: "",
      //   invenID: "",
      //   fancyColor: "",
      //   intIntensity: 0,
      //   overtone: "",
      //   overtone2: "",
      //   country: "",
      //   shade: "",
      //   treatment: "",
      //   keytoSymbol: "",
      //   fluorescenceColor: "",
      //   blackInclusion: "",
      //   centralInclusion: "",
      //   milkyInclusion: "",
      //   eyeclean: "",
      //   ratioType: "",
      //   permission: "",
      //   video: "",
      //   strCertSolrQuery: "",
      //   strDetailLinkURL: "",
      //   pageName: "",
      // };
      setLoading(true);
      SelectYourDiamondService.GetRingBuilderSelectMinedDiamond(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var FirstRow = response.data.responseData[0];
          if (message == "Success") {
            if (responseData.length > 0) {
              setMinedList(responseData);
              setTotalRowCount(responseData[0].intTotalRecords);
              setRowDetails(FirstRow);
              setLoading(false);
            } else {
              setMinedList([]);
              setTotalRowCount(0);
              setLoading(false);
            }
          } else {
            setMinedList([]);
            setTotalRowCount(0);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function onChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
    }
  }
  function onShowSizeChange(current, pageNewSize) {
    if (pageNewSize !== pageSize) {
      setPageSize(pageNewSize);
    }
  }

  const handleSetRowDetils = (e, record) => {
    console.log(e, record);
    setRowDetails(record);
  };

  const handleOpenVideoImageModal = (row, isVideo) => {
    setIsVideo(isVideo);
    setSelectedRowDetails(row);
    setVideoImageModal(true);
  };
  const handleCloseVideoImageModal = () => {
    setVideoImageModal(false);
  };

  const handleSearchDiamond = (value) => {
    setSearchSku(value);
  };

  const handleFilterChangeCaratVal = (e) => {
    setCaratSlideValue(e);
  };

  const handleFilterChangePriceVal = (e) => {
    setPriceValue(e);
  };

  return (
    <Spin spinning={showAllContent} style={{ minHeight : '50vh'}}>
    {showAllContent == false &&
    <React.Fragment>
      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <div className="tabs__content">
          <div className="diamond__details">
            <div className="row border__block">
              <div className="col-md-9">
                <div className="row">
                  <Spin spinning={filterloading}>
                    <div className="form__fields filter_inpsection border-0 p-0 mt-3 mb-3 mh-auto">
                      <div className="col-md-12">
                        <div className="row">
                          <Wrapper>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Shape
                                  {/* <Tooltip
                                  placement="right"
                                  title="A diamond’s shape is not the same as a diamond’s cut. The shape refers to the general outline of the stone, and not its light refractive qualities. Look for a shape that best suits the ring setting you have chosen, as well as the recipient’s preference and personality."
                                >
                                  <img src={UnionIcon} alt="" />
                                </Tooltip> */}
                                </label>
                              </div>
                            </div>
                            <div className="whole__shapes col-lg-12">
                              {state.Shape &&
                                state.Shape.map((item, i) => {
                                  return (
                                    <Fragment>
                                      <div
                                        className={
                                          filterVals.Shape &&
                                          filterVals.Shape.includes(item.value)
                                            ? "shapes__block selected"
                                            : "shapes__block"
                                        }
                                        onClick={() =>
                                          handleLabGrownFilterChange(
                                            item.value,
                                            "Shape",
                                            "filter"
                                          )
                                        }
                                      >
                                        <div className="image">
                                          <img src={item.shapeImage} />
                                        </div>
                                        <p>{item.name}</p>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                            </div>

                            <div className="col-md-12">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Cut
                                  {/* <Tooltip
                                  placement="right"
                                  title="Not to be confused with shape, a diamond’s cut rating tells you how well its proportions interact with light. By evaluating the angles and proportions of the diamond, the cut grade is designed to tell you how sparkly and brilliant your stone is. Cut grading is usually not available for fancy shapes (any shape that is not round), because the mathematical formula that determines light return becomes less reliable when different length to width ratios are factored in."
                                >
                                  <img src={UnionIcon} alt="" />
                                </Tooltip> */}
                                </label>
                              </div>
                              <div className="selection__block row">
                                {state.Cut &&
                                  state.Cut.map((item, i) => {
                                    return (
                                      <Fragment>
                                        <div className="col">
                                          <div
                                            className={
                                              filterVals.Cut &&
                                              filterVals.Cut.includes(
                                                item.value
                                              )
                                                ? "selection__box selected"
                                                : "selection__box"
                                            }
                                            onClick={() =>
                                              handleLabGrownFilterChange(
                                                item.value,
                                                "Cut",
                                                "filter"
                                              )
                                            }
                                          ></div>
                                          <p>{item.name}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Color
                                  {/* <Tooltip
                                  placement="right"
                                  title="The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone. For most diamonds, this is in the F-H range."
                                >
                                  <img src={UnionIcon} alt="" />
                                </Tooltip> */}
                                </label>
                              </div>
                              <div className="selection__block row">
                                {state.Color &&
                                  state.Color.map((item, i) => {
                                    return (
                                      <Fragment>
                                        <div className="col">
                                          <div
                                            className={
                                              filterVals.Color &&
                                              filterVals.Color.includes(
                                                item.value
                                              )
                                                ? "selection__box selected"
                                                : "selection__box"
                                            }
                                            onClick={() =>
                                              handleLabGrownFilterChange(
                                                item.value,
                                                "Color",
                                                "filter"
                                              )
                                            }
                                          ></div>
                                          <p>{item.name}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Clarity
                                  {/* <Tooltip
                                  placement="right"
                                  title="The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone. For most diamonds, this is in the F-H range."
                                >
                                  <img src={UnionIcon} alt="" />
                                </Tooltip> */}
                                </label>
                              </div>
                              <div className="selection__block row">
                                {state.Clarity &&
                                  state.Clarity.map((item, i) => {
                                    return (
                                      <Fragment>
                                        <div className="col">
                                          <div
                                            className={
                                              filterVals.Clarity &&
                                              filterVals.Clarity.includes(
                                                item.value
                                              )
                                                ? "selection__box selected"
                                                : "selection__box"
                                            }
                                            onClick={() =>
                                              handleLabGrownFilterChange(
                                                item.value,
                                                "Clarity",
                                                "filter"
                                              )
                                            }
                                          ></div>
                                          <p>{item.name}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                          </Wrapper>
                          <div className="col-md-6">
                            <div className="sliderrange__maindiv">
                              <label> {"Carats"} </label>
                              <div className="col-md-12 mt-3">
                                <Slider
                                  range
                                  value={caratSlideValue}
                                  min={state.CaratMin}
                                  max={state.CaratMax}
                                  step={0.01}
                                  onChange={(e) => {
                                    handleFilterChangeCaratVal(e);
                                  }}
                                  onAfterChange={(e) => handleAfterChange(e)}
                                />
                                <div className="rangeSlider__values">
                                  <div className="input__block">
                                    <input
                                      type="text"
                                      value={caratSlideValue[0].toLocaleString(
                                        "en-US"
                                      )}
                                      onChange={(e) => {
                                        firstsetCaratSlideValue(e, 0);
                                      }}
                                      onKeyPress={onKeyPressEvent1}
                                    />
                                  </div>

                                  <div className="input__block">
                                    <input
                                      type="text"
                                      value={caratSlideValue[1].toLocaleString(
                                        "en-US"
                                      )}
                                      onChange={(e) => {
                                        firstsetCaratSlideValue(e, 1);
                                      }}
                                      onKeyPress={onKeyPressEvent1}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="sliderrange__maindiv">
                              <label> {"Price Range"} </label>
                              <div className="col-md-12 mt-3">
                                <Slider
                                  range
                                  value={priceValue}
                                  min={state.PriceMin}
                                  max={state.PriceMax}
                                  onChange={(e) => {
                                    handleFilterChangePriceVal(e);
                                  }}
                                  onAfterChange={(e) => handleAfterChangePrice(e)}
                                />
                                <div className="rangeSlider__values">
                                  <div className="input__block">
                                    <input
                                      type="text"
                                      // value={
                                      //   "$" +
                                      //   priceValue[0].toLocaleString("en-US")
                                      // }
                                      value={priceValue[0]
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                      onChange={(e) => {
                                        firstSliderPriceValue(e, 0);
                                      }}
                                    />
                                  </div>

                                  <div className="input__block">
                                    <input
                                      type="text"
                                      // value={
                                      //   "$" +
                                      //   priceValue[1].toLocaleString("en-US")
                                      // }
                                      value={
                                        currencySymbol +
                                        priceValue[1]
                                          .toString()
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      }
                                      onChange={(e) => {
                                        firstSliderPriceValue(e, 1);
                                        // handleSliderCaratValue(e);
                                        // setCaratSlideValue(e);
                                      }}
                                      onKeyPress={onKeyPressEvent1}
                                    />
                                    {/* <span className="dollar__symbol">$</span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Wrapper>
                            {isAdvance && (
                              <div className="col-md-12 mt-2">
                                <div className="input__block d-flex align-item-center">
                                  <button
                                    className="primary-btn mr-1"
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      padding: "0px",
                                    }}
                                    onClick={() =>
                                      setShowAdvanced(!showAdvanced)
                                    }
                                  >
                                    {showAdvanced ? "-" : "+"}
                                  </button>
                                  <label>Advanced</label>
                                </div>
                              </div>
                            )}
                            </Wrapper>

                            {showAdvanced == true ? (
                              <div>
                                <Wrapper>
                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <label>
                                      {" "}
                                      Polish
                                      {/* <Tooltip placement="right" title="">
                                      <img src={UnionIcon} alt="" />
                                    </Tooltip> */}
                                    </label>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Polish &&
                                      state.Polish.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Polish &&
                                                  filterVals.Polish.includes(
                                                    item.value
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.value,
                                                    "Polish",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <label>
                                      {" "}
                                      Fluorescence
                                      {/* <Tooltip placement="right" title="">
                                      <img src={UnionIcon} alt="" />
                                    </Tooltip> */}
                                    </label>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Fluorescence &&
                                      state.Fluorescence.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Fluorescence &&
                                                  filterVals.Fluorescence.includes(
                                                    item.value
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.value,
                                                    "Fluorescence",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <label>
                                      {" "}
                                      Symmetry
                                      {/* <Tooltip placement="right" title="">
                                      <img src={UnionIcon} alt="" />
                                    </Tooltip> */}
                                    </label>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Symmetry &&
                                      state.Symmetry.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Symmetry &&
                                                  filterVals.Symmetry.includes(
                                                    item.value
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.value,
                                                    "Symmetry",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                                </Wrapper>
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="input__block mb-1">
                                      <label>Depth</label>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <input
                                            type="number"
                                            value={filterVals.DepthMin}
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e.target.value,
                                                "DepthMin",
                                                "input"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-md-6 mobile__topspace">
                                          <input
                                            type="number"
                                            value={filterVals.DepthMax}
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e.target.value,
                                                "DepthMax",
                                                "input"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="input__block">
                                      <label>Table</label>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <input
                                            type="number"
                                            value={filterVals.TableMin}
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e.target.value,
                                                "TableMin",
                                                "input"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-md-6 mobile__topspace">
                                          <input
                                            type="number"
                                            value={filterVals.TableMax}
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e.target.value,
                                                "TableMax",
                                                "input"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="input__block">
                                      <label>Certificates</label>
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder="Certificates"
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleLabGrownFilterChange(
                                            e,
                                            "Certificates",
                                            "input"
                                          )
                                        }
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        mode="multiple"
                                        value={filterVals.Certificates}
                                      >
                                        {state.Certificates.map((cert) => {
                                          return (
                                            <Option value={cert.value}>
                                              {cert.name}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          {/* </Wrapper> */}
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>
              <div className="col-md-3">
                <div className="diamondimage__block">
                  <p>
                    Please enter the details of the type of diamond you are
                    looking for and your contact information.
                  </p>
                  <p>
                    Your information will be submitted to one of our diamond
                    specialists that will contact you.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="table__block filter_section mt-3">
              <h5 className="subheading"> No Diamonds Listed In That Range </h5>
              <p>
                {" "}
                Please enter your contact information. One of our diamond
                specialists will contact you and assist you with your needs.{" "}
              </p>
              <div class="row p-0 border-0 form__fields mh-auto">
                <div class="col-md-6">
                  <div class="input__block">
                    <label> First Name </label>
                    <input
                      type="text"
                      placeholder="Enter First Name"
                      name="firstName"
                      value={diamondFormData.firstName}
                      onChange={(e) =>
                        handleInputDiamondDataChange(e, "firstName")
                      }
                    />
                    <div>
                      {validations.firstNameVal && (
                        <p className="error-color-red">
                          {validations.firstNameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input__block">
                    <label> Last Name </label>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      name="lastName"
                      value={diamondFormData.lastName}
                      onChange={(e) =>
                        handleInputDiamondDataChange(e, "lastName")
                      }
                    />
                    <div>
                      {validations.lastNameVal && (
                        <p className="error-color-red">
                          {validations.lastNameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input__block">
                    <label> Phone Number </label>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      name="phoneNumber"
                      value={diamondFormData.phoneNumber}
                      onChange={(e) =>
                        handleInputDiamondDataChange(e, "phoneNumber")
                      }
                    />
                    <div>
                      {validations.phoneNumberVal && (
                        <p className="error-color-red">
                          {validations.phoneNumberVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input__block">
                    <label> Email Address </label>
                    <input
                      type="text"
                      placeholder="Enter Email Address"
                      name="emailAddress"
                      value={diamondFormData.emailAddress}
                      onChange={(e) =>
                        handleInputDiamondDataChange(e, "emailAddress")
                      }
                    />
                    <div>
                      {validations.emailAddressVal && (
                        <p className="error-color-red">
                          {validations.emailAddressVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input__block">
                    <label> Comments </label>
                    <textarea
                      name="comments"
                      value={diamondFormData.comments}
                      onChange={(e) =>
                        handleInputDiamondDataChange(e, "comments")
                      }
                    ></textarea>
                    <div>
                      {validations.commentsVal && (
                        <p className="error-color-red">
                          {validations.commentsVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <Wrapper>
                  <div class="col-md-12">
                    <div class="save__form">
                      <Button
                        loading={reqDiamondloading}
                        className="mr-1"
                        type="primary"
                        onClick={handleInputDiamondDataRequest}
                      >
                        {" "}
                        Request{" "}
                      </Button>
                      <Button
                        type="primary"
                        class="primary-btn"
                        onClick={handleInputDiamondDataReset}
                      >
                        {" "}
                        Reset{" "}
                      </Button>
                    </div>
                  </div>
                </Wrapper>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modalconsupld__section"
        style={{ top: 20 }}
        title={"Video"}
        width={500}
        visible={videoImageModal}
        onCancel={() => handleCloseVideoImageModal()}
        footer={[
          <Button onClick={() => handleCloseVideoImageModal()}>
            {"Cancel"}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  {isVideo ? (
                    selectedRowDetails.videoFileName ? (
                      <iframe
                        width="100%"
                        height="450"
                        title="diamond-video"
                        className="VideoIframe"
                        src={selectedRowDetails.videoFileName}
                      ></iframe>
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  ) : selectedRowDetails.imageFileName ? (
                    <img
                      src={selectedRowDetails.imageFileName}
                      alt="img-diamond"
                    />
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
    }
    </Spin>
  );
};

export default Request;
