import React, { useState, Fragment, useEffect, useLayoutEffect } from "react";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import {
  handleColorButtonChange,
  handleSwitchChange,
} from "../actions/PreviewColor/previewColor";

import { useLocation } from "react-router-dom";
import ColorPanelService from "../services/color-panel.service";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { Button } from "antd";
import close_red from "../assets/images/icons/close_red.svg";
import { hover } from "@testing-library/user-event/dist/hover";

const ColorPanel = () => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const [isChooseCss, setIsChooseCss] = useState(false);
  const [hoverColor, setHoverColor] = useState("");
  const [columnHeaderAccentColor, setColumnHeaderAccentColor] = useState("");
  const [fontValue, setFontValue] = useState("1");
  const [linkColor, setLinkColor] = useState("");
  const [callToActionColor, setCallToActionColor] = useState("");
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [initialColorObj, setInitialColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  const [dealerId, setDealerId] = useState();
  const [loading, setLoading] = useState(false);
  const [filterloading, setFilterLoading] = useState(false);
  const [name, setName] = useState("");

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const paramDealerId = new URLSearchParams(search).get("DealerLink");

  useLayoutEffect(() => {
    if (paramDealerId) {
      handleGetColorsData(paramDealerId);
      setDealerId(paramDealerId);
    }
  }, [paramDealerId]);

  useEffect(() => {
    if (loginDetails) {
      if (loginDetails.dealerId) {
        setDealerId(loginDetails.dealerId);
      }
    }
  }, [paramDealerId]);

  useEffect(() => {
    if (isChooseCss == true) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isChooseCss]);

  useEffect(() => {
    if (previewcolor) {
      if (previewcolor.buttonColor) {
        console.log(previewcolor);
        setVarColorObj({
          ...varColorObj,
          callToActionColor: previewcolor.buttonColor.callToActionColor,
          hoverColor: previewcolor.buttonColor.hoverColor,
          columnHeaderAccentColor:
            previewcolor.buttonColor.columnHeaderAccentColor,
          linkColor: previewcolor.buttonColor.linkColor,
        });
        setCallToActionColor(previewcolor.buttonColor.callToActionColor);
        setHoverColor(previewcolor.buttonColor.hoverColor);
        setLinkColor(previewcolor.buttonColor.linkColor);
        setColumnHeaderAccentColor(
          previewcolor.buttonColor.columnHeaderAccentColor
        );

        setToggle(previewcolor.toogle);
      }
    }
  }, [previewcolor.toogle, toggle]);

  const handleColorDisChange = (type) => {
    handleAddUpdateColorsData(type);
  };

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = (name) => {
    setIsHovering(true);
    setName(name);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    setName("");
  };

  var primaryBtn = {
    backgroundColor: varColorObj.callToActionColor,
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    color: "#fff",
    border: "none",
    padding: "0px 10px",
    height: "35px",
  };

  var primaryBtnHover = {
    backgroundColor: varColorObj.hoverColor,
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    color: "#fff",
    border: "none",
    padding: "0px 10px",
    height: "35px",
  };

  const handleChooseCss = () => {
    setIsChooseCss(!isChooseCss);
  };

  const handleColorChange = (e, name) => {
    if (name == "hover") {
      setHoverColor(e.target.value);
    }
    if (name == "columnHeader") {
      setColumnHeaderAccentColor(e.target.value);
    }
    if (name == "linkColor") {
      setLinkColor(e.target.value);
    }
    if (name == "ctaColor") {
      setCallToActionColor(e.target.value);
    }
  };

  const handleResetColor = () => {
    setHoverColor("");
    setColumnHeaderAccentColor("");
    setLinkColor("");
    setCallToActionColor("");
  };
  const onFontChange = (e) => {
    setFontValue(e.target.value);
  };
  // need to add the dispatch method for the needed color change
  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "StudBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setLoading(true);
    try {
      await ColorPanelService.GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              setInitialColorObj({
                ...initialColorObj,
                callToActionColor: initialColorObj.actionButton,
                hoverColor: initialColorObj.hoverEffect,
                columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                linkColor: initialColorObj.linkColor,
              });

              var tempobj = colorObj;

              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;

              if (tempobj) {
                dispatch(handleSwitchChange());
                dispatch(handleColorButtonChange(tempobj));
              } else {
                dispatch(handleColorButtonChange(initialColorObj));
              }
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAddUpdateColorsData = async (type) => {
    let inputData;
    if (type == "Save") {
      inputData = {
        dealerID: Number(dealerId),
        moduleName: "StudBuilder",
        hoverEffect: hoverColor,
        columnHeaderAccent: columnHeaderAccentColor,
        linkColor: linkColor,
        actionButton: callToActionColor,
      };
    } else {
      inputData = {
        dealerID: Number(dealerId),
        moduleName: "StudBuilder",
        hoverEffect: "",
        columnHeaderAccent: "",
        linkColor: "",
        actionButton: "",
      };
    }
    setFilterLoading(true);
    try {
      await ColorPanelService.AddUpdateHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          //debugger;
          if (msg == "Success") {
            if (type == "Save") {
              NotificationManager.success("Color Panel Updated Successfully");
              handleGetColorsData(dealerId);
            } else {
              NotificationManager.success(
                "Color Panel has been Reset Successfully"
              );
              handleGetColorsData(dealerId);
            }
            setFilterLoading(false);
          } else {
            if (type == "Save") {
              NotificationManager.success("Could Not Update Color Panel");
              handleGetColorsData(dealerId);
            } else {
              NotificationManager.success("Could Not Reset Color Panel");
              handleGetColorsData(dealerId);
            }
            setFilterLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setFilterLoading(false);
        });
    } catch (error) {
      console.log(error);
      setFilterLoading(false);
    }
  };

  const [showColor, setShowColor] = useState(false);

  useEffect(() => {
    window.addEventListener("message", handleIframeTask);
  }, []);

  const handleIframeTask = (e) => {
    if (e.data === "Show") {
      setShowColor(true);
    }
  };

  return (
    <Fragment>
      <div
        className={
          isChooseCss
            ? "slidebar__section__open slidebar__section"
            : "slidebar__section"
        }
      >
        <button
          onClick={handleChooseCss}
          // className="primary-btn slidebar__btn m-0"
          className={
            showColor == true ? "primary-btn slidebar__btn m-0" : "display-none"
          }
        >
          <i class="fa fa-cog m-0" aria-hidden="true"></i>
        </button>
        <div className="right__sidebar">
          <div className="scrolling__area">
            <div className="font__block pb-0">
              <div className="row">
                <div className="col-6">
                  <p className="font__bold">Choose Font :</p>
                </div>
                <div className="col-6 text-right" onClick={handleChooseCss}>
                  <img src={close_red} />
                </div>
              </div>

              <div className="font__options">
                <p>
                  <span>Font option1</span>{" "}
                  <input
                    type="radio"
                    value="1"
                    checked={fontValue === "1"}
                    onChange={onFontChange}
                  />
                </p>
                <p>
                  <span>Font option2</span>{" "}
                  <input
                    type="radio"
                    value="2"
                    checked={fontValue === "2"}
                    onChange={onFontChange}
                  />
                </p>
                <p>
                  <span>Font option3</span>{" "}
                  <input
                    type="radio"
                    value="3"
                    checked={fontValue === "3"}
                    onChange={onFontChange}
                  />
                </p>
                <p>
                  <span>Font option4</span>{" "}
                  <input
                    type="radio"
                    value="4"
                    checked={fontValue === "4"}
                    onChange={onFontChange}
                  />
                </p>
                <p>
                  <span>Font option5</span>{" "}
                  <input
                    type="radio"
                    value="5"
                    checked={fontValue === "5"}
                    onChange={onFontChange}
                  />
                </p>
              </div>
            </div>
            <hr />
            <div className="colorselection__block">
              <p className="font__bold">Hover Effect : </p>
              <div className="color__section">
                <div className="left__block w-50">
                  <input type="color" value="#000022" />
                  <p>{initialColorObj.hoverColor}</p>
                </div>
                <div className="right__block w-50">
                  <input
                    type="color"
                    value={hoverColor}
                    onChange={(e) => handleColorChange(e, "hover")}
                  />
                  <div className="input__block mb-0">
                    <input
                      type="text"
                      onChange={(e) => handleColorChange(e, "hover")}
                      value={hoverColor}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="colorselection__block">
              <p className="font__bold">Column Header Accent : </p>
              <div className="color__section">
                <div className="left__block w-50">
                  <input type="color" value="#000000" />
                  <p>{initialColorObj.columnHeaderAccentColor}</p>
                </div>
                <div className="right__block w-50">
                  <input
                    type="color"
                    value={columnHeaderAccentColor}
                    onChange={(e) => handleColorChange(e, "columnHeader")}
                  />
                  <div className="input__block mb-0">
                    <input
                      type="text"
                      onChange={(e) => handleColorChange(e, "columnHeader")}
                      value={columnHeaderAccentColor}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="colorselection__block">
              <p className="font__bold">Link Color : </p>
              <div className="color__section">
                <div className="left__block w-50">
                  <input type="color" value="#828282" />
                  <p>{initialColorObj.linkColor}</p>
                </div>
                <div className="right__block w-50">
                  <input
                    type="color"
                    value={linkColor}
                    onChange={(e) => handleColorChange(e, "linkColor")}
                  />
                  <div className="input__block mb-0">
                    <input
                      type="text"
                      onChange={(e) => handleColorChange(e, "linkColor")}
                      value={linkColor}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="colorselection__block">
              <p className="font__bold">Call to Action Button : </p>
              <div className="color__section">
                <div className="left__block w-50">
                  <input type="color" value="#000022" />
                  <p>{initialColorObj.callToActionColor}</p>
                </div>
                <div className="right__block w-50">
                  <input
                    type="color"
                    value={callToActionColor}
                    onChange={(e) => handleColorChange(e, "ctaColor")}
                  />
                  <div className="input__block mb-0">
                    <input
                      type="text"
                      onChange={(e) => handleColorChange(e, "ctaColor")}
                      value={callToActionColor}
                    />
                  </div>
                </div>
              </div>
              <div className="sidebar__btn mt-4">
                <Button
                  // style={
                  //   isHovering && name == "Save" ? primaryBtnHover : primaryBtn
                  // }
                  type="primary"
                  className="mr-2 primaryBtn"
                  // onMouseEnter={() => handleMouseEnter("Save")}
                  // onMouseLeave={handleMouseLeave}
                  onClick={() => handleColorDisChange("Save")}
                >
                  Save
                </Button>
                <Button
                  // style={
                  //   isHovering && name == "Reset" ? primaryBtnHover : primaryBtn
                  // }
                  type="primary"
                  // className="primaryBtn"
                  // onMouseEnter={() => handleMouseEnter("Reset")}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleColorDisChange("Reset")}
                >
                  Reset
                </Button>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <NotificationContainer />
    </Fragment>
  );
};

export default ColorPanel;
